import { FC } from 'react';
import * as service from 'src/service';
import Fade from 'react-reveal/Fade';
import { contactSchema } from 'src/utils/validation';
import { sendContactInitialValues } from 'src/utils/values';
import { Seo } from 'src/constants';

import {
  ActionButton,
  InputField,
  InputLabel,
  TextAreaField,
} from 'src/components/Forms';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import { Form, Formik, FormikValues } from 'formik';
import { Box, Typography, Card, Grid, CardContent } from '@mui/material';
import { useStyles } from './ContactStyles';

const Contact: FC = () => {
  const classes = useStyles();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();

  const onSendContact = (values: FormikValues) => {
    service.contact
      .sendContact(values)
      .then((res) => {
        if (res.response.status === 200) {
          changeContinuePopup('Your message has been sent successfully');
          setTimeout(() => {
            changeContinuePopup('Your message has been sent successfully ');
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Seo title='Contact' />
      <Box className={classes.contactContainer}>
        <Box className={classes.contactWrapper}>
          <Typography variant='h2'>
            <Fade bottom cascade>
              CONTACT US
            </Fade>
          </Typography>
        </Box>
      </Box>

      <section className={classes.section}>
        <Grid container spacing={2} className={classes.gridLine}>
          <Grid item xs={12} sm={12} md={8}>
            <Card sx={{ boxShadow: 'none' }}>
              <CardContent className={classes.card}>
                {changingConfirmed && (
                  <ChangeConfirmedPopup
                    successText={successText}
                    changingConfirmed={changingConfirmed}
                  />
                )}
                <Typography variant='h4'>
                  kindly fill our contact form
                </Typography>
                <Formik
                  initialValues={sendContactInitialValues}
                  validationSchema={contactSchema}
                  onSubmit={(values: FormikValues, actions) => {
                    onSendContact(values);
                    actions.setSubmitting(false);
                    actions.resetForm();
                  }}
                >
                  {({
                    handleSubmit,
                    isSubmitting,
                    handleChange,
                    errors,
                    touched,
                    handleBlur,

                    values,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Box className={classes.inputWrapper}>
                            <InputLabel id='first name' title='First Name*' />
                            <InputField
                              placeholder='enter your first name...'
                              name='firstName'
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Box className={classes.inputWrapper}>
                            <InputLabel id='last name' title='Last Name*' />
                            <InputField
                              placeholder='enter your last name...'
                              name='lastName'
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className={classes.inputWrapper}>
                            <InputLabel id='email' title='Email*' />
                            <InputField
                              placeholder='enter your email...'
                              name='email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box className={classes.inputWrapper}>
                            <InputLabel id='message' title='Message*' />
                            <TextAreaField
                              placeholder='enter your message...'
                              minRows={8}
                              name='message'
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                touched.message && errors.message
                                  ? classes.errorMessage
                                  : classes.textArea
                              }
                            />
                            {errors.message && touched.message && (
                              <Box className={classes.error}>
                                {errors.message as string}
                              </Box>
                            )}
                          </Box>
                        </Grid>
                        <ActionButton
                          type='submit'
                          disabled={isSubmitting}
                          className={classes.btnSend}
                        >
                          Send Message
                        </ActionButton>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} sx={{ marginTop: '3rem' }}>
            <Card className={classes.contactCardWrapper}>
              <CardContent className={classes.contactCard}>
                <Box className={classes.contactInfo}>
                  <Typography variant='h4'>Contact Information:</Typography>
                  <Typography variant='body2'>070012FRONTIER,</Typography>
                  <Typography variant='body2'>
                    admin@frontiergroupltd.com
                  </Typography>

                  <Box className={classes.address}>
                    <Typography variant='h4'>Contact Address:</Typography>
                    <Typography variant='body2'>
                      Head Office: 144, Egbeda-Idimu road, Seliat bus-stop,
                      Egbeda Lagos
                    </Typography>
                    &nbsp;
                    <Typography variant='body2'>
                      Ikeja-Branch: 3, Balogun street Anifowose, by Railway
                      crossing ikeja, lagos
                    </Typography>
                    &nbsp;
                    <Typography variant='body2'>
                      Ojo/Iba-Branch: 40, Igbo Elerin Road, Martin B/Stop,
                      Okokomaiko, ojo Lagos
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.458273504148!2d3.284823674202365!3d6.589820093403843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9047ecee4503%3A0x67b1865cb48c3593!2sFrontier%20Homes!5e0!3m2!1sen!2sua!4v1682203094610!5m2!1sen!2sua'
          width='600'
          height='450'
          style={{ border: 0, width: '100%' }}
          allowFullScreen={true}
          loading='lazy'
          title='map'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </section>
    </>
  );
};

export default Contact;
