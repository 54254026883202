import { FC } from 'react';
import Fade from 'react-reveal/Fade';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './ExpectationsStyles';

const Expectations: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Fade left>
        <Typography className={classes.whyUs} variant='h3'>
          Why is Frontier Homes the perfect choice?
        </Typography>
        <Grid container spacing={3} className={classes.gridLine}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.number} variant='body2'>
              01.
            </Typography>
            <Typography className={classes.title} variant='body2'>
              PROMPT ALLOCATION
            </Typography>
            <Typography className={classes.description} variant='body2'>
              We are known for prompt allocation. This is one core value we hold
              dearly and have been true to. We are one of the fastest when it
              comes to allocation of lands in the real estate industry in
              Nigeria.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.number} variant='body2'>
              02.
            </Typography>
            <Typography className={classes.title} variant='body2'>
              INTEGRITY
            </Typography>
            <Typography className={classes.description} variant='body2'>
              A wise man once said that integrity is the seed of achievement.
              This principle we hold dearly and never fail to live by. We are
              big on integrity which is why our existing clients refer us to
              their family and friends.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.number} variant='body2'>
              03.
            </Typography>
            <Typography className={classes.title} variant='body2'>
              QUALITY SERVICE
            </Typography>
            <Typography className={classes.description} variant='body2'>
              We care about our clients, this is why we give them excellent
              service. Our team members are well trained to render top notch
              service at all times.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.number} variant='body2'>
              04.
            </Typography>
            <Typography className={classes.title} variant='body2'>
              CUSTOMER SATISFACTION
            </Typography>
            <Typography className={classes.description} variant='body2'>
              This is our ultimate goal. We strive daily to ensure that our
              prospects, clients and returning clients are satisfied to the best
              of our capabilities.
            </Typography>
          </Grid>
        </Grid>
      </Fade>
    </section>
  );
};

export default Expectations;
