import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[100],
    marginBottom: '2rem',
  },

  gridLine: {
    padding: '2rem 3rem 4rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '1rem 1.5rem 3rem',
    },
  },
  whyUs: {
    fontSize: '2.4rem',
    fontWeight: 700,
    paddingTop: '2rem',
    textAlign: 'center',
    lineHeight: '1.5',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '2rem 1.5rem',
      lineHeight: '1.2',
      fontSize: '2rem',
    },
  },

  number: {
    fontSize: '2.7rem',
    fontWeight: 700,
    marginBottom: '1rem',
  },

  title: {
    fontSize: '1.2rem',
    fontWeight: 700,
    marginBottom: '1rem',
  },

  description: {
    fontSize: '1rem',
    margin: '0px',
    fontWeight: 300,
    color: theme.palette.grey[200],
  },
}));
