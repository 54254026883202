import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  homeWrapper: {
    //   padding: '0 3rem',
    //   [theme.breakpoints.down('md')]: {
    //     padding: '0 1.5rem',
    //   },
  },
}));
