import { get } from './api';

export const getHomeProperties = () =>
  get({
    url: '/properties',
  });

export const getFeaturedProperties = () => get({ url: '/properties/featured' });

export const getBanners = () => get({ url: '/banners' });
