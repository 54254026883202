import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },

  cardWrapper: {
    backgroundColor: 'rgba(25, 31, 69, 0.9)',
    borderRadius: '5px',
    padding: '3rem 2rem',
    zIndex: -1,
    color: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1rem',
    },
    '& h4': {
      fontSize: '1.5rem',
      fontWeight: 700,
      textTransform: 'uppercase',
      marginBottom: '1.5rem',
      textAlign: 'center',
    },
  },

  input: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.black[100],
  },

  select: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 40,
    color: theme.palette.grey[600],
    width: '100%',
  },
  btnGetDetails: {
    backgroundColor: theme.palette.white.main,
    textTransform: 'capitalize',
    padding: '.7em 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '700',
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',

    '&:hover': {
      backgroundColor: theme.palette.white.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  inputError: {
    border: '1px solid red',
    backgroundColor: theme.palette.background.paper,
    maxHeight: 40,
    color: theme.palette.grey[600],
  },

  error: {
    color: 'red',
    marginTop: '0',
    fontSize: '0.8rem',
    paddingLeft: '1rem',
  },

  label: {
    fontSize: '0.88rem',
  },
}));
