import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useActivePage = () => {
  const navigate = useNavigate();
  let activeUrlPage = new URLSearchParams(window.location.search).get('page');
  const [pageNumbers, setPageNumbers] = useState<number>(
    activeUrlPage ? Number(activeUrlPage) : 1
  );
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (activeUrlPage && (+activeUrlPage < 1 || isNaN(+activeUrlPage))) {
      return navigate('/404');
    }
  }, [activeUrlPage, navigate]);

  useEffect(() => {
    setPageLoaded(false);
    let newParams = new URLSearchParams(window.location.search);
    newParams.set('page', pageNumbers.toString());
    window.history.replaceState(
      null,
      '',
      window.location.pathname + '?' + newParams.toString()
    );
    window.scrollTo(0, 0);
    setPageLoaded(true);
    return () => setPageLoaded(false);
  }, [pageNumbers]);
  return { pageNumbers, setPageNumbers, pageLoaded } as const;
};

export default useActivePage;
