import { useState } from 'react';

const useBottomPopup = () => {
  const [changingConfirmed, setChangingConfirmed] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');

  let changeContinuePopup = (text = 'Changes saved successfully') => {
    setChangingConfirmed((state) => !state);
    setSuccessText(text);
  };

  return { successText, changingConfirmed, changeContinuePopup } as const;
};

export default useBottomPopup;
