import blog from 'src/assets/blog.webp';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  blogContainer: {
    backgroundImage: `url(${blog})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50vh',
    width: '100%',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '50vh',
      background: 'rgba(0,0,0,0.3)',
    },
  },

  blogWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    position: 'relative',
    zIndex: 1,
    '& h2': {
      color: theme.palette.background.paper,
      fontSize: '4rem',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '3rem',
      },
    },
  },

  section: {
    backgroundColor: theme.palette.grey[100],
    padding: '2rem 3rem',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 1.5rem',
    },
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: ' 2rem',
  },
}));
