import { FC, useState } from 'react';
import PropertiesImage from './PropertiesImage';
import moment from 'moment';
import Schedule from './Schedule';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { useStyles } from './PropertiesDetailsCardsStyles';

interface IPropertiesDetailsCards {
  property: Record<string, any>;
}
const PropertiesDetailsCards: FC<IPropertiesDetailsCards> = ({ property }) => {
  const classes = useStyles();
  const [imageViewer, setImageViewer] = useState({
    show: false,
    currentImg: 0,
  });

  const prevImage = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      currentImg: prevState.currentImg - 1,
    }));
  };

  const nextImage = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      currentImg: prevState.currentImg + 1,
    }));
  };

  const closeImageViewer = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      show: false,
    }));
  };
  return (
    <>
      <Box>
        <PropertiesImage
          property={property}
          imageViewer={imageViewer}
          prevImage={prevImage}
          nextImage={nextImage}
          closeImageViewer={closeImageViewer}
          setImageViewer={setImageViewer}
        />
      </Box>
      <Box className={classes.overView}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent>
            <Typography variant='h3'>Overview</Typography>
            <Box className={classes.propertyType}>
              <Typography variant='body2'>Property Type</Typography>
              <Typography variant='body2'>{property?.propertyType}</Typography>
            </Box>
            <Box className={classes.propertySize}>
              <Typography variant='body2'>Property Size</Typography>
              <Typography variant='body2'>
                {property?.propertySize} sqm
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box className={classes.descriptionWrapper}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent className={classes.content}>
            <Typography variant='h3'>Property Description</Typography>
            <Typography variant='body2'>
              {property?.propertyDescription}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box className={classes.locationWrapper}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent className={classes.content}>
            <Typography variant='h3'>Location</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.location}>
                  <Typography variant='body2'>Address</Typography>
                  <Typography variant='body2'>
                    {property?.propertyAddress?.street}
                  </Typography>
                </Box>
                <Box className={classes.location}>
                  <Typography variant='body2'>city</Typography>
                  <Typography variant='body2'>{property?.category}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.location}>
                  <Typography variant='body2'>Zip/Postal Code</Typography>
                  <Typography variant='body2'>
                    {property?.propertyAddress?.zipCode}
                  </Typography>
                </Box>
                <Box className={classes.location}>
                  <Typography variant='body2'>Country</Typography>
                  <Typography variant='body2'>
                    {property?.propertyAddress?.country}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box className={classes.detailsWrapper}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent className={classes.content}>
            <Box className={classes.details}>
              <Typography variant='h3'>Details</Typography>
              <Box className={classes.timePosted}>
                <CalendarMonthIcon />
                <Typography variant='body2'>
                  {moment.utc(property.createdAt).startOf('minute').fromNow()}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.detailsInfo}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.propertiesDetails}>
                    <Typography variant='body2'>Price:</Typography>
                    <Typography variant='body2'>
                      ₦{property?.propertyPrice?.toLocaleString()}
                    </Typography>
                  </Box>
                  <Box className={classes.propertiesDetails}>
                    <Typography variant='body2'>Land Area: </Typography>
                    <Typography variant='body2'>
                      {property?.category}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.propertiesDetails}>
                    <Typography variant='body2'>Property Size:</Typography>
                    <Typography variant='body2'>
                      {property?.propertySize} sqm
                    </Typography>
                  </Box>
                  <Box className={classes.propertiesDetails}>
                    <Typography variant='body2'>Property Type:</Typography>
                    <Typography variant='body2'>
                      {property?.propertyType}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.additionalDetailsWrapper}>
              <Typography variant='h3'>Additional Details</Typography>
              <Box className={classes.additionalDetails}>
                <Typography variant='body2'>Title Document:</Typography>
                <Typography variant='body2'>
                  {property?.propertyAdditionalDetails?.title}
                </Typography>
              </Box>
              <Box className={classes.additionalDetails}>
                <Typography variant='body2'>Guaranteed Benefits :</Typography>
                <Typography variant='body2'>
                  {property?.propertyAdditionalDetails?.benefits}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Schedule />
    </>
  );
};

export default PropertiesDetailsCards;
