import { FC, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Seo } from 'src/constants';
import * as service from 'src/service';
import { PropertiesCard, PropertiesCardFeatures } from 'src/components/CardBox';
import PropertiesGridIcon from '../PropertiesGridIcon';
import { routing } from 'src/utils';
import { useActivePage, useSort } from 'src/components';
import Paginations from 'src/constants/Paginations';
import { Box, Typography, Grid, Breadcrumbs } from '@mui/material';
import { useStyles } from '../PropertiesStyles';

const PropertiesCities: FC = () => {
  const classes = useStyles();
  const [activeGrid, setActiveGrid] = useState('column-grid');
  const { name } = useParams<{ name: string }>();
  const { pageNumbers, setPageNumbers, pageLoaded } = useActivePage();
  const { properties, setProperties, sort, setSort, onSort } = useSort();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (name && pageLoaded) {
      service.property
        .getPropertyName(name, pageNumbers, 10)
        .then((res) => {
          setProperties(res.data.properties);
          setTotalPages(res.data.total);
          setIsLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [name, pageLoaded, pageNumbers, setProperties]);

  return (
    <section className={classes.section}>
      <Seo title={name as string} />
      <Box className={classes.container}>
        <Box sx={{ marginBottom: '1rem' }}>
          <Breadcrumbs separator='›' aria-label='breadcrumb'>
            <Link to={routing.home} className={classes.link}>
              <Typography className={classes.breadcrumbs}>Home</Typography>
            </Link>
            <Typography color='text.primary'>{name}</Typography>
          </Breadcrumbs>
        </Box>

        <Box className={classes.propertiesWrapper}>
          <Typography variant='h2'>{name}</Typography>
          <PropertiesGridIcon
            activeGrid={activeGrid}
            setActiveGrid={setActiveGrid}
          />
        </Box>

        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          <Grid item xs={12} sm={12} md={8}>
            <PropertiesCard
              gridType={activeGrid}
              properties={onSort}
              isLoading={isLoading}
              setSort={setSort}
              sort={sort}
            />
            {totalPages > 1 && properties.length && (
              <Box className={classes.paginationWrapper}>
                <Paginations
                  pageNumbers={pageNumbers}
                  setPageNumbers={setPageNumbers}
                  isTotalPages={totalPages}
                  loading={isLoading}
                  handleNewLoading={() => {
                    setIsLoading(true);
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <PropertiesCardFeatures />
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default PropertiesCities;
