import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionButton } from 'src/components/Forms';
import { Image } from 'src/components';
import * as service from 'src/service';
import { routing } from 'src/utils';
import { FeaturedPropertiesSlider } from 'src/components/Slider';
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useStyles } from './PropertiesCardFeaturesStyles';

const PropertiesCardFeatures: FC = () => {
  const classes = useStyles();
  const [featuredProperties, setFeaturedProperties] = useState<
    Record<string, any>[]
  >([]);
  const [propertiesType, setPropertiesType] = useState<Record<string, any>[]>(
    []
  );
  const [propertyName, setPropertyName] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    Promise.all([
      service.property.getPropertyFeatured(),
      service.property.getPropertyTypes(),
      service.property.getAllPropertyNames(),
    ])
      .then((res) => {
        const filtereOutFeaturedNotEmpty = res[0].data.properties.filter(
          (property: Record<string, any>) => property.propertyFeatured !== ''
        );
        setFeaturedProperties(filtereOutFeaturedNotEmpty);
        setPropertiesType(res[1].data.properties);
        setPropertyName(res[2].data.properties);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <Box className={classes.featuresWrapper}>
        {featuredProperties.length !== 0 ? (
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box className={classes.features}>
                <Typography variant='h3'>Best Selling Locations</Typography>
                <Grid container spacing={2} className={classes.sliderContainer}>
                  <FeaturedPropertiesSlider>
                    {featuredProperties.map((property) => (
                      <Grid item key={property._id}>
                        <Link to={`${routing.property}/${property.slug}`}>
                          <Box className={classes.imageWrapper}>
                            <Image
                              src={property.propertyImage[0].url}
                              alt={property.propertyName}
                              fallback={property.propertyImage[0].url}
                              className={classes.propertyImg}
                            />

                            <Box className={classes.feauredList}>
                              <ActionButton className={classes.btnFeatured}>
                                {property.propertyFeatured}
                              </ActionButton>
                              <ActionButton className={classes.btnStatus}>
                                {property.propertyStatus}
                              </ActionButton>
                            </Box>
                            <Box className={classes.priceWrapper}>
                              <Typography variant='h4'>
                                ₦{property.propertyPrice.toLocaleString()}
                              </Typography>
                              <Typography variant='body2'>
                                {property.propertyName}
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      </Grid>
                    ))}
                  </FeaturedPropertiesSlider>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ) : null}
      </Box>

      <Box className={classes.propertyWrapper}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box className={classes.typeWrapper}>
              <Typography variant='h3'>Property Type</Typography>
              <List disablePadding>
                {propertiesType.map((property) => (
                  <Link
                    key={property._id}
                    to={`${routing.propertyType}/${property._id}`}
                    className={classes.link}
                  >
                    <ListItem>
                      <KeyboardArrowRightIcon />
                      <ListItemText primary={property._id} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box className={classes.citiesWrapper}>
              <Typography variant='h3'>Cities</Typography>
              <List>
                {propertyName.map((name) => (
                  <Link
                    key={name._id}
                    to={`${routing.propertyName}/${name._id}`}
                    className={classes.link}
                  >
                    <ListItem>
                      <KeyboardArrowRightIcon />
                      <ListItemText primary={name._id} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default PropertiesCardFeatures;
