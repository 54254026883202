import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    backgroundColor: theme.palette.grey[100],
  },

  container: {
    padding: '3rem 3rem',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 1.5rem',
    },
  },

  breadcrumbs: {
    color: theme.palette.lightBlue.dark,
  },

  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '1rem',
    },
    '& h2:nth-child(1)': {
      fontSize: '1.7rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      color: theme.palette.black[100],
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '70%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset',
      },
    },
    '& h2:nth-child(2)': {
      fontSize: '1.7rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      color: theme.palette.black[100],
      [theme.breakpoints.down('sm')]: {
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },

  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    marginBottom: '1rem',
  },

  btnFeatured: {
    backgroundColor: theme.palette.primary.main,
    padding: '.2rem 1rem',
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnStatus: {
    backgroundColor: theme.palette.grey[600],
    padding: '.2rem 1rem',
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  locationWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    marginBottom: '1rem',
    color: theme.palette.grey[700],
    '& p': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.grey[700],
    },
  },

  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));
