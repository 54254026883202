import { post } from './api';

export const sendContact = (body: Record<string, any>) =>
  post({ url: '/contact', body });

export const sendSchedule = (body: Record<string, any>) =>
  post({ url: '/schedule', body });

export const sendClient = (body: Record<string, any>) =>
  post({ url: '/client', body });

export const sendLocation = (body: Record<string, any>) =>
  post({ url: '/location', body });
