import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      color: theme.palette.white.main,
      fontSize: '3rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      margin: '0 0 1rem',
      maxWidth: '90%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '2.5rem',
        maxWidth: 'unset',
        textAlign: 'center',
        marginTop: '1.5rem',
      },
    },
    '& p': {
      color: theme.palette.white.main,
      fontSize: '1.3rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      marginBottom: '2rem',
      textAlign: 'center',
    },
  },

  searchWrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    padding: '.6rem .5rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '.6rem',
  },

  searchColumnDirection: {
    flexDirection: 'column',
    alignItems: 'unset',
    marginBottom: '1.5rem',
  },

  btnSearch: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  select: {
    maxHeight: 40,
    color: theme.palette.grey[600],
  },
  input: {
    flex: 1,
  },

  searchInput: {
    minWidth: '100px',
  },

  btnProperty: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '1rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      marginBottom: '2rem',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnWrapper: {
    minHeight: '200px',
    [theme.breakpoints.down('md')]: {
      minHeight: '150px',
    },
  },
}));
