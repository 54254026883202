import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from 'src/utils';
import { Formik, Form } from 'formik';
import { SelectField, ActionButton, InputField } from '../../Forms';
import * as service from 'src/service';
import cx from 'classnames';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useStyles } from './LeftSideFieldsStyles';

const initialState = {
  name: '',
  category: '',
};

const LeftSideFields: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isSearchColumnDirection = useMediaQuery('(max-width: 745px)');
  const isSearchCard = useMediaQuery('(max-width: 336px)');
  const [cities, setCities] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    service.property
      .getAllCategories()
      .then((res) => {
        setCities(res.data.categories);
      })
      .catch(console.error);
  }, []);

  const onSearch = (values: { name: string; category: string }) => {
    if (!values.name && !values.category) return;
    if (values.name) {
      navigate(`${routing.propertySearch}?name=${values.name}`);
    } else {
      navigate(`${routing.propertySearch}?cities=${values.category}`);
    }
  };
  return (
    <Box className={classes.searchContainer}>
      <Typography variant='h1'>frontier commercial services limited</Typography>
      <Typography variant='body2'>
        let's help you create wealth through Real Estate in Nigeria
      </Typography>
      <Formik
        initialValues={initialState}
        onSubmit={(values) => {
          onSearch(values);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.btnWrapper}>
              <Box
                className={cx(classes.searchWrapper, {
                  [classes.searchColumnDirection]: isSearchColumnDirection,
                })}
              >
                <SelectField
                  value={values.category}
                  options={cities.map((city) => ({
                    _id: city._id,
                    value: city.name,
                  }))}
                  className={classes.select}
                  name='category'
                  onChange={(e) => {
                    setFieldValue('category', e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  placeholder='All cities'
                />

                <InputField
                  placeholder='Enter an address, town, street'
                  type='text'
                  value={values.name}
                  onChange={handleChange}
                  name='name'
                  size='small'
                  className={cx(classes.input, {
                    [classes.searchInput]: isSearchCard,
                  })}
                />
                <ActionButton type='submit' className={classes.btnSearch}>
                  Search
                </ActionButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      <ActionButton
        onClick={() => navigate(routing.properties)}
        className={classes.btnProperty}
      >
        Explore our Properties
      </ActionButton>
    </Box>
  );
};

export default LeftSideFields;
