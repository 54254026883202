import { FC } from 'react';
import Fade from 'react-reveal/Fade';
import ReactPlayer from 'react-player';
import { testimonialsOne, testimonialsTwo } from 'src/constants/links';
import { Typography, Box, Grid } from '@mui/material';
import { useStyles } from './TestimonialsStyles';

const Testimonials: FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Box className={classes.textWrapper}>
        <Fade bottom cascade>
          <Typography className={classes.title} variant='h2'>
            Testimonials
          </Typography>
        </Fade>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <ReactPlayer
            url={testimonialsOne}
            controls
            width='100%'
            style={{ objectFit: 'cover' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ReactPlayer
            url={testimonialsTwo}
            controls
            width='100%'
            style={{ objectFit: 'cover' }}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default Testimonials;
