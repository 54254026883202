import { FC, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { routing } from 'src/utils';
import { Seo } from 'src/constants';
import { useActivePage } from 'src/components';
import Paginations from 'src/constants/Paginations';
import * as service from 'src/service';
import { BlogSideCard, BlogCardFeatures } from 'src/components/CardBox';
import { Box, Typography, Breadcrumbs, Grid } from '@mui/material';
import { useStyles } from './BlogUserStyles';

const BlogUser: FC = () => {
  const classes = useStyles();
  const { user } = useParams<{ user: string }>();
  const { pageNumbers, setPageNumbers, pageLoaded } = useActivePage();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [blogs, setBlogs] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    if (user && pageLoaded) {
      service.blog
        .getBlogUser(user, pageNumbers, 10)
        .then((res) => {
          setBlogs(res.data.blogs);
          setTotalPages(res.data.total);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  }, [user, pageLoaded, pageNumbers]);
  return (
    <section className={classes.section}>
      <Seo title={blogs[0]?.user?.name} />
      <Box className={classes.blogWrapper}>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          <Link to={routing.home} className={classes.blogTitleLink}>
            <Typography className={classes.titleNav}>Home</Typography>
          </Link>
          <Typography className={classes.user}>
            {blogs[0]?.user?.name}
          </Typography>
        </Breadcrumbs>

        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography className={classes.usersTitle} variant='h2'>
              {blogs[0]?.user?.name}
            </Typography>
            <BlogCardFeatures blogs={blogs} isLoading={isLoading} />
            {totalPages > 1 && blogs.length && (
              <Box className={classes.paginationWrapper}>
                <Paginations
                  pageNumbers={pageNumbers}
                  setPageNumbers={setPageNumbers}
                  isTotalPages={totalPages}
                  loading={isLoading}
                  handleNewLoading={() => {
                    setIsLoading(true);
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <BlogSideCard />
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default BlogUser;
