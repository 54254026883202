import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import * as routing from 'src/utils/routing';
import * as service from 'src/service';
import { ActionButton } from 'src/components/Forms';
import { PropertyCard } from 'src/components/CardBox';
import { Typography, useMediaQuery } from '@mui/material';
import { useStyles } from './FeaturedStyles';

const Featured: FC = () => {
  const classes = useStyles();
  const isText = useMediaQuery('(max-width: 386px)');

  const [featured, setFeatured] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    service.property
      .getPropertyFeatured()
      .then((res) => {
        const filtereOutFeaturedNotEmpty = res.data.properties.filter(
          (property: Record<string, any>) => property.propertyFeatured !== ''
        );
        setFeatured(filtereOutFeaturedNotEmpty);
        setIsLoading(false);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {featured.length !== 0 ? (
        <section className={classes.section}>
          {isText ? (
            <Fade bottom cascade>
              <Typography className={classes.title} variant='h2'>
                Best Selling
              </Typography>
              <Typography className={classes.titleMobile} variant='h2'>
                Locations
              </Typography>
            </Fade>
          ) : (
            <Fade bottom cascade>
              <Typography className={classes.title} variant='h2'>
                Best Selling Locations
              </Typography>
            </Fade>
          )}
          <Fade bottom>
            <Typography className={classes.description} variant='body2'>
              Here are a few of our top selling estate locations
            </Typography>
          </Fade>
          <PropertyCard properties={featured} isLoading={isLoading} />
          <Link to={routing.properties} className={classes.link}>
            <ActionButton className={classes.btnProperty}>
              Explore our Properties
            </ActionButton>
          </Link>
        </section>
      ) : null}
    </>
  );
};

export default Featured;
