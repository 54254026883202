import { FC } from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import { Box } from '@mui/material';
import { useStyles } from '../PropertiesStyles';

interface PropertiesGridIconProps {
  activeGrid: string;
  setActiveGrid: (grid: string) => void;
}

const PropertiesGridIcon: FC<PropertiesGridIconProps> = ({
  activeGrid,
  setActiveGrid,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.gridIconWrapper}>
      <ViewAgendaIcon
        style={{
          cursor: 'pointer',
          color: activeGrid === 'column-grid' ? '#3f51b5' : '#000',
        }}
        onClick={() => setActiveGrid('column-grid')}
      />

      <GridViewIcon
        style={{
          cursor: 'pointer',
          color: activeGrid === 'row-grid' ? '#3f51b5' : '#000',
        }}
        onClick={() => setActiveGrid('row-grid')}
      />
    </Box>
  );
};

export default PropertiesGridIcon;
