import { lazy } from 'react';
import {
  BlogDetails,
  BlogCategories,
  BlogTags,
  BlogUser,
  BlogSearch,
  PropertiesName,
  PropertiesType,
  PropertiesStatus,
  PropertiesUser,
  PropertiesDetails,
  SearchProperties,
  Page404,
} from '../pages';

const HomPage = lazy(() => import('../pages/Home'));
const AboutPage = lazy(() => import('../pages/About'));
const ContactPage = lazy(() => import('../pages/Contact'));
const BlogPage = lazy(() => import('../pages/Blog'));
const PropertiesPage = lazy(() => import('../pages/Properties'));

export const Navigation = {
  mainNavigation: [
    {
      path: '/',
      main: () => <HomPage />,
    },
    {
      path: '/about',
      main: () => <AboutPage />,
    },
    {
      path: '/contact',
      main: () => <ContactPage />,
    },

    {
      path: '/blog',
      main: () => <BlogPage />,
    },
    {
      path: '/properties',
      main: () => <PropertiesPage />,
    },

    {
      path: '/property/name/:name',
      main: () => <PropertiesName />,
    },
    {
      path: '/property/type/:propertyType',
      main: () => <PropertiesType />,
    },
    {
      path: '/property/status/:propertyStatus',
      main: () => <PropertiesStatus />,
    },
    {
      path: '/property/user/:user',
      main: () => <PropertiesUser />,
    },
    {
      path: '/property/:slug',
      main: () => <PropertiesDetails />,
    },

    {
      path: '/search/property',
      main: () => <SearchProperties />,
    },

    {
      path: '/blog/:slug',
      main: () => <BlogDetails />,
    },
    {
      path: '/blog/category/:category',
      main: () => <BlogCategories />,
    },
    {
      path: '/blog/tag/:tag',
      main: () => <BlogTags />,
    },

    {
      path: '/blog/user/:user',
      main: () => <BlogUser />,
    },

    {
      path: '/search/blog',
      main: () => <BlogSearch />,
    },
    {
      path: '/404',
      main: () => <Page404 />,
    },
  ],
};
