import { useState, useMemo } from 'react';

const useSort = () => {
  const [properties, setProperties] = useState<Record<string, any>[]>([]);
  const [sort, setSort] = useState<string>('Newest');

  const onSort = useMemo(() => {
    let result = properties;
    if (sort === 'Newest') {
      result = [...properties].sort((a, b) => {
        return b.createdAt.localeCompare(a.createdAt);
      });
    }

    if (sort === 'Oldest') {
      result = [...properties].sort((a, b) => {
        return a.createdAt.localeCompare(b.createdAt);
      });
    }

    if (sort === 'Price: Low to High') {
      result = [...properties].sort((a, b) => {
        return a.propertyPrice > b.propertyPrice ? 1 : -1;
      });
    }

    if (sort === 'Price: High to Low') {
      result = [...properties].sort((a, b) => {
        return b.propertyPrice > a.propertyPrice ? 1 : -1;
      });
    }
    return result;
  }, [properties, sort]);

  return { properties, setProperties, sort, setSort, onSort } as const;
};

export default useSort;
