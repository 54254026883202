export const green = {
  50: '#dbece2',
  100: '#a7d0b8',
  200: '#6eb18b',
  300: '#2a9461',
  400: '#008044',
  500: '#006c27',
  600: '#006020',
  700: '#005116',
  800: '#00410b',
  900: '#002700',
};
export const purple = {
  50: '#e9eaf0',
  100: '#c7cada',
  200: '#a3a8c1',
  300: '#8186a7',
  400: '#686c95',
  500: '#505485',
  600: '#494c7d',
  700: '#414371',
  800: '#393964',
  900: '#2d2a4c',
};

export const white = {
  50: '#ffffff',
  100: '#ffffff',
  200: '#ffffff',
  300: '#ffffff',
  400: '#ffffff',
  500: '#ffffff',
  600: '#ffffff',
  700: '#ffffff',
  800: '#ffffff',
  900: '#ffffff',
};
export const black = {
  50: '#000000',

  100: '#000000',
  200: '#000000',
  300: '#000000',
  400: '#000000',
  500: '#000000',
  600: '#000000',
  700: '#000000',

  800: '#000000',
  900: '#000000',
};
export const red = {
  50: '#fde9e9',
  100: '#f9c2c2',
  200: '#f49999',
  300: '#f06f6f',
  400: '#ec4a4a',
  500: '#e82a2a',
  600: '#e51e1e',
  700: '#e00f0f',
  800: '#d80000',
  900: '#c90000',
};
export const orange = {
  50: '#fff3e0',
  100: '#ffe0b2',
  200: '#ffcc80',
  300: '#ffb74d',
  400: '#ffa726',
  500: '#ff9800',
  600: '#fb8c00',
  700: '#f57c00',
  800: '#ef6c00',
  900: '#e65100',
};
export const yellow = {
  50: '#fffde7',
  100: '#fff9c4',
  200: '#fff59d',
  300: '#fff176',

  400: '#ffee58',

  500: '#ffeb3b',
  600: '#fdd835',
  700: '#fbc02d',
  800: '#f9a825',
  900: '#f57f17',
};
export const blue = {
  100: '#d3d4de',
  200: '#a6a9be',
  300: '#7a7f9d',
  400: '#4d547d',
  500: '#21295c',
  600: '#191F45', // manually adjusted
  700: '#141937',
  800: '#0d1025',
  900: '#070812',
};

export const lightBlue = {
  50: '#e3f2fd',
  100: '#bbdefb',
  200: '#90caf9',
  300: '#64b5f6',
  400: '#42a5f5',
  500: '#2196f3',
  600: '#1e88e5',
  700: '#1976d2',
  800: '#1565c0',
  900: '#0d47a1',
};
export const indigo = {
  50: '#e8eaf6',
  100: '#c5cae9',
  200: '#9fa8da',

  300: '#7986cb',
  400: '#5c6bc0',

  500: '#3f51b5',
  600: '#3949ab',

  700: '#303f9f',
  800: '#283593',
  900: '#1a237e',
};
export const teal = {
  50: '#e0f2f1',
  100: '#b2dfdb',
  200: '#80cbc4',
  300: '#4db6ac',
  400: '#26a69a',
  500: '#009688',
  600: '#00897b',
  700: '#00796b',
  800: '#00695c',
  900: '#004d40',
};

export const grey = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
};
export const blueGrey = {
  50: '#eceff1',
  100: '#cfd8dc',
  200: '#b0bec5',
  300: '#90a4ae',
  400: '#78909c',
  500: '#607d8b',
  600: '#546e7a',
  700: '#455a64',
  800: '#37474f',
  900: '#263238',
};

export const theme = {
  palette: {
    primary: {
      main: blue[600],
      light: blue[100],
      dark: blue[900],
    },
    secondary: {
      main: green[500],
      light: green[100],
      dark: green[900],
    },
    error: {
      main: red[500],
      light: red[100],
      dark: red[900],
    },
    warning: {
      main: yellow[500],
      light: yellow[100],
      dark: yellow[900],
    },
    info: {
      main: indigo[500],
      light: indigo[100],
      dark: indigo[900],
    },
    success: {
      main: teal[500],
      light: teal[100],
      dark: teal[900],
    },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[600],
      disabled: blueGrey[400],
      hint: blueGrey[400],
    },

    black: {
      main: black[900],
      light: black[100],
      dark: black[900],
    },
    orange: {
      main: orange[500],
      light: orange[100],
      dark: orange[900],
    },
    lightBlue: {
      main: lightBlue[500],
      light: lightBlue[100],
      dark: lightBlue[900],
    },

    background: {
      default: '#fff',
      paper: '#fff',
    },
    green: {
      main: green[500],
      light: green[100],
      dark: green[900],
    },
    white: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
    },
    action: {
      active: blueGrey[900],
      hover: blueGrey[100],
      hoverOpacity: 0.08,
      selected: blueGrey[200],
      disabled: blueGrey[100],
      disabledBackground: blueGrey[50],
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  shape: {
    borderRadius: 4,
  },
  shadows: [
    'none',

    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',

    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',

    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',

    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',

    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',

    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',

    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',

    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',

    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',

    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  ],

  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },

    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,

    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
};
