import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  detailsTitle: {
    fontSize: '1.5rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: '1rem',
    color: theme.palette.black[100],
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  detailsTime: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: theme.palette.primary.grey,
  },

  detailsImg: {
    width: '100%',
    objectFit: 'cover',
    marginBottom: '1.5rem',
  },

  detailsText: {
    fontSize: '1rem',
    fontWeight: 300,
    color: theme.palette.primary.grey,
    marginBottom: '1rem',
    lineHeight: '1.8rem',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& img': {
      width: '100%',
      objectFit: 'cover',
    }
  },
  detailsTags: {
    margin: '2rem 0 1rem',
    '& p': {
      fontSize: '1rem',
      fontWeight: 400,
      color: theme.palette.primary.grey,
      marginBottom: '.8rem',
    },
  },

  btnTags: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },
  btnTagsWrapper: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  detailsTimeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },

  userWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },

  blogTitleLink: {
    textDecoration: 'none',
    color: '#263238',
  },
}));
