import React, { FC } from 'react';
import Slider from 'react-slick';
import { FeaturedSettings } from '../../../constants/ChangingSlider';

interface FeaturedSettingsProps {
  children: React.ReactNode;
}
const FeaturedPropertiesSlider: FC<FeaturedSettingsProps> = ({ children }) => {
  return <Slider {...FeaturedSettings}>{children}</Slider>;
};

export default FeaturedPropertiesSlider;
