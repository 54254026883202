export const timeOptions = [
  { _id: '10: 00 am', value: '10:00 am' },
  { _id: '10: 15 am', value: '10:15 am' },
  { _id: '10: 30 am', value: '10:30 am' },
  { _id: '10: 45 am', value: '10:45 am' },
  { _id: '11: 00 am', value: '11:00 am' },
  { _id: '11: 15 am', value: '11:15 am' },
  { _id: '11: 30 am', value: '11:30 am' },
  { _id: '11: 45 am', value: '11:45 am' },
  { _id: '12: 00 pm', value: '12:00 pm' },
  { _id: '12: 15 pm', value: '12:15 pm' },
  { _id: '12: 30 pm', value: '12:30 pm' },
  { _id: '12: 45 pm', value: '12:45 pm' },
  { _id: '1: 00 pm', value: '1:00 pm' },
  { _id: '1: 15 pm', value: '1:15 pm' },
  { _id: '1: 30 pm', value: '1:30 pm' },
  { _id: '1: 45 pm', value: '1:45 pm' },
  { _id: '2: 00 pm', value: '2:00 pm' },
  { _id: '2: 15 pm', value: '2:15 pm' },
  { _id: '2: 30 pm', value: '2:30 pm' },
  { _id: '2: 45 pm', value: '2:45 pm' },
  { _id: '3: 00 pm', value: '3:00 pm' },
  { _id: '3: 15 pm', value: '3:15 pm' },
  { _id: '3: 30 pm', value: '3:30 pm' },
  { _id: '3: 45 pm', value: '3:45 pm' },
  { _id: '4: 00 pm', value: '4:00 pm' },
  { _id: '4: 15 pm', value: '4:15 pm' },
  { _id: '4: 30 pm', value: '4:30 pm' },
  { _id: '4: 45 pm', value: '4:45 pm' },
  { _id: '5: 00 pm', value: '5:00 pm' },
];
