import { FC, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Seo } from 'src/constants';
import { routing } from 'src/utils';
import { useActivePage } from 'src/components';
import Paginations from 'src/constants/Paginations';
import * as service from 'src/service';
import { BlogSideCard, BlogCardFeatures } from 'src/components/CardBox';
import { Box, Typography, Breadcrumbs, Grid } from '@mui/material';
import { useStyles } from './BlogCategoriesStyles';

const BlogCategories: FC = () => {
  const { category } = useParams<{ category: string }>();
  const { pageNumbers, setPageNumbers, pageLoaded } = useActivePage();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [blogs, setBlogs] = useState<Record<string, any>[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (category && pageLoaded) {
      service.blog
        .getBlogCategory(category, pageNumbers, 10)
        .then((res) => {
          setBlogs(res.data.blogs);
          setTotalPages(res.data.total);
          setIsLoading(false);
        })
        .catch(console.log)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [category, pageLoaded, pageNumbers]);
  return (
    <section className={classes.section}>
      <Seo title={category as string} />
      <Box className={classes.blogWrapper}>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          <Link to={routing.home} className={classes.blogTitleLink}>
            <Typography className={classes.titleNav}>Home</Typography>
          </Link>
          <Typography className={classes.category}>{category}</Typography>
        </Breadcrumbs>

        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography className={classes.categoriesTitle} variant='h2'>
              {category}
            </Typography>
            <BlogCardFeatures blogs={blogs} isLoading={isLoading} />
            {totalPages > 1 && blogs.length && (
              <Box className={classes.paginationWrapper}>
                <Paginations
                  pageNumbers={pageNumbers}
                  setPageNumbers={setPageNumbers}
                  isTotalPages={totalPages}
                  loading={isLoading}
                  handleNewLoading={() => {
                    setIsLoading(true);
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <BlogSideCard />
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default BlogCategories;
