import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from 'src/utils';
import cx from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import Logo from 'src/assets/logo4.png';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { useStyles } from './MobileHeaderMenuStyles';

const menu = [
  {
    url: routing.home,
    name: 'Home',
  },
  {
    url: routing.about,
    name: 'About',
  },
  {
    url: routing.properties,
    name: 'Properties',
  },
  {
    url: routing.blog,
    name: 'Blog',
  },
  {
    url: routing.contact,
    name: 'Contact',
  },
];

const MobileHeaderMenu: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const burgerToggle = () => setOpen(!open);

  return (
    <header className={classes.headerContainer}>
      <Box className={classes.IconWrapper}>
        {open ? (
          <CloseIcon
            onClick={burgerToggle}
            style={{ color: 'white', zIndex: 6 }}
          />
        ) : (
          <MenuIcon onClick={burgerToggle} style={{ color: 'white' }} />
        )}
        <img
          onClick={() => navigate(routing.home)}
          src={Logo}
          alt='logo'
          className={classes.logo}
        />
      </Box>
      <nav className={classes.navWrapper}>
        <Box
          className={cx(classes.navMenuSide, { [classes.navMenuOpen]: open })}
        >
          {menu.map((item, index) => (
            <List key={index} className={classes.listItem}>
              <ListItem
                className={classes.listItem}
                onClick={() => {
                  navigate(item.url);
                  burgerToggle();
                }}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            </List>
          ))}
        </Box>
      </nav>
    </header>
  );
};

export default MobileHeaderMenu;
