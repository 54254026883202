import { FC, useState } from 'react';
import * as service from 'src/service';
import { clientOptions } from './data';
import { clientSchema } from 'src/utils/validation';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import {
  SelectField,
  ActionButton,
  InputField,
  TextAreaField,
} from 'src/components/Forms';
import cx from 'classnames';
import logo from 'src/assets/logo4.png';
import PersonIcon from '@mui/icons-material/Person';
import { Form, Formik, FormikValues } from 'formik';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Checkbox,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useStyles } from './PropertiesSideFeaturesStyles';

interface PropertiesSideFeaturesProps {
  propertyName: string;
}
const PropertiesSideFeatures: FC<PropertiesSideFeaturesProps> = ({
  propertyName,
}) => {
  const isLogoColumnDirection = useMediaQuery('(max-width: 339px)');
  const isButtonColumnDirection = useMediaQuery('(max-width: 346px)');

  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [showNumber, setShowNumber] = useState(false);
  const classes = useStyles();

  const onShowContactNumber = () => setShowNumber(!showNumber);

  const onSendClient = (values: FormikValues) => {
    service.contact.sendClient(values).then((res) => {
      if (res.response.status === 200) {
        changeContinuePopup('Your message has been sent');
        setTimeout(() => {
          changeContinuePopup('Your message has been sent ');
        }, 3000);
      }
    });
  };

  return (
    <Box className={classes.sideBar}>
      {changingConfirmed && (
        <ChangeConfirmedPopup
          successText={successText}
          changingConfirmed={changingConfirmed}
        />
      )}
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Box
            className={cx(classes.logoWrapper, {
              [classes.logoWrapperMobile]: isLogoColumnDirection,
            })}
          >
            <img src={logo} alt='logo' className={classes.logo} />
            <Box className={classes.person}>
              <PersonIcon />
              <Typography variant='body1'>by frontier homes</Typography>
            </Box>
          </Box>

          <Formik
            initialValues={{
              name: '',
              phone: '',
              email: '',
              message: `Hello, I am interested in ${propertyName}`,
              client: '',
              terms: false,
            }}
            validationSchema={clientSchema}
            onSubmit={(values: FormikValues, actions) => {
              onSendClient(values);
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              handleChange,
              errors,
              touched,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box className={classes.inputWrapper}>
                  <InputField
                    placeholder='Name...'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <InputField
                    placeholder='Email...'
                    name='email'
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <InputField
                    placeholder='Phone...'
                    name='phone'
                    value={values.phone}
                    onChange={handleChange}
                    fullWidth
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <TextAreaField
                    placeholder='Message...'
                    name='message'
                    value={values.message}
                    onChange={handleChange}
                    minRows={8}
                    className={
                      touched.message && errors.message
                        ? classes.error
                        : classes.textArea
                    }
                  />

                  {touched.message && errors.message && (
                    <Box className={classes.errorText}>
                      {errors.message as string}
                    </Box>
                  )}
                </Box>
                <Box className={classes.inputWrapper}>
                  <SelectField
                    name='client'
                    options={clientOptions.map((option) => option)}
                    onChange={(e) => setFieldValue('client', e.target.value)}
                    value={values.client}
                    fullWidth
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    placeholder='I am a...'
                    className={
                      touched.client && errors.client ? classes.error : ''
                    }
                  />
                  {touched.client && errors.client && (
                    <Box className={classes.errorText}>
                      {errors.client as string}
                    </Box>
                  )}
                </Box>

                <Box className={classes.checkboxWrapper}>
                  <Checkbox
                    checked={values.terms}
                    onChange={() => setFieldValue('terms', !values.terms)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography variant='body1'>
                    By Submitting this form, you agree to &nbsp;
                    <span className={classes.termUse}>Terms of Use</span>
                  </Typography>
                </Box>
                <Box className={classes.btnGroup}>
                  <Box
                    className={cx(classes.messageWrapper, {
                      [classes.messageWrapperMobile]: isButtonColumnDirection,
                    })}
                  >
                    <ActionButton
                      type='submit'
                      disabled={isSubmitting || !values.terms}
                      className={classes.btnSend}
                    >
                      Send Message
                    </ActionButton>
                    <ActionButton
                      onClick={onShowContactNumber}
                      className={classes.btnCall}
                    >
                      {showNumber ? '070012FRONTIER' : 'Call'}
                    </ActionButton>
                  </Box>
                  <a
                    href='https://wa.me/2348082869977'
                    className={classes.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Button
                      className={classes.btnWhatsapp}
                      variant='outlined'
                      startIcon={<WhatsAppIcon />}
                    >
                      whatsApp
                    </Button>
                  </a>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PropertiesSideFeatures;
