import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  featuresWrapper: {
    marginBottom: '3rem',
  },
  sliderContainer: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: '2rem',
    padding: '2rem  0  2rem 1rem',
  },

  imageWrapper: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.2)',
      zIndex: 1,
    },
  },
  features: {
    padding: '1.3rem 1.5rem 1rem',
    '& h3': {
      fontSize: '1.3rem',
      fontWeight: 700,
    },
  },
  propertyImg: {
    width: '100%',
    objectFit: 'cover',
    height: '300px',
  },

  feauredList: {
    position: 'absolute',
    top: '.4rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    zIndex: 2,
  },

  priceWrapper: {
    position: 'absolute',
    bottom: '1rem',
    left: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: 2,
    '& > h4': {
      fontSize: '1.3rem',
      fontWeight: 700,
      color: theme.palette.white.main,
    },
    '& > p': {
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.white.main,
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  propertyWrapper: {
    marginBottom: '3rem',
  },

  btnFeatured: {
    backgroundColor: theme.palette.primary.main,
    padding: '0rem .6rem',
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },
  btnStatus: {
    backgroundColor: theme.palette.grey[600],
    padding: '0rem .6rem',
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  card: {
    boxShadow: 'none',
  },

  cardContent: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  typeWrapper: {
    padding: '1.3rem 1rem 0.5rem',
    '& h3': {
      fontSize: '1.2rem',
      fontWeight: 700,
      marginLeft: '1rem',
      color: theme.palette.black[100],
    },
  },
  citiesWrapper: {
    padding: '1.3rem 1rem 0.5rem',
    '& h3': {
      fontSize: '1.2rem',
      fontWeight: 700,
      marginLeft: '1rem',
      color: theme.palette.black[100],
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));
