import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  chatWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
    width: '60px',
    borderRadius: '50%',
    backgroundColor: theme.palette.green.main,
    position: 'fixed',
    bottom: '2rem',
    left: '2rem',
    zIndex: 7,
  },

  chatboxBg: {
    position: 'fixed',
    bottom: '2rem',
    left: '2rem',
    zIndex: 8,
    [theme.breakpoints.down('sm')]: {
      left: '1.5rem',
    },
  },

  content: {
    backgroundColor: theme.palette.grey[100],
    width: '428px',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  frontierWrapper: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1rem',
    marginBottom: '1rem',

    '& > h2': {
      color: theme.palette.white.main,
      fontSize: '1.5rem',
      fontWeight: 700,
    },
  },

  help: {
    padding: '1rem 1rem',
    marginBottom: '1rem',
  },

  helpContent: {
    '&:last-child': {
      paddingBottom: '.9rem',
    },
    '& p': {
      fontSize: '1rem',
      fontWeight: 300,
    },
  },

  btnSend: {
    padding: '1rem 2rem',
    marginLeft: '1rem',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    boxShadow: 'none',
    borderRadius: '30px',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));
