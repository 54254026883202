import React, { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    hieght: '100vh',
  },
}));

const Loader: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
