import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '@mui/material';

interface IPaginations {
  isTotalPages: number;
  loading: boolean;
  handleNewLoading: () => void;
  pageNumbers: number;
  setPageNumbers: React.Dispatch<React.SetStateAction<number>>;
}

const Paginations: FC<IPaginations> = ({
  isTotalPages,
  loading,
  setPageNumbers,
  handleNewLoading,
  pageNumbers,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && pageNumbers && isTotalPages)
      if (isTotalPages < pageNumbers) {
        navigate('/404');
      }
  }, [loading, pageNumbers, navigate, isTotalPages]);
  return (
    <Pagination
      count={+isTotalPages}
      page={pageNumbers}
      onChange={(event, value) => {
        setPageNumbers(value);
        if (handleNewLoading) handleNewLoading();
      }}
      variant='outlined'
      shape='rounded'
      color='primary'
    />
  );
};

export default Paginations;
