import React, { FC, useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import MobileHeaderMenu from './MobileHeaderMenu';
import { routing } from 'src/utils';
import cx from 'classnames';
import Logo from 'src/assets/logo4.png';
import { SearchProperties } from 'src/components';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useStyles } from './HeaderStyles';

const menu = [
  {
    name: 'Home',
    path: routing.home,
  },
  {
    name: 'About',
    path: routing.about,
  },
  {
    name: 'Properties',
    path: routing.properties,
  },
  {
    name: 'Blog',
    path: routing.blog,
  },
  {
    name: 'Contact',
    path: routing.contact,
  },
];

const Header: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery(useTheme().breakpoints.up('md'));
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(pathname.substring(0));
  }, [pathname]);

  const classes = useStyles();
  return (
    <>
      <Box
        className={cx({
          [classes.headerContainer]: activeLink === routing.home,
        })}
      >
        {isNonMobile && (
          <header
            className={cx({
              [classes.headerWrapper]: activeLink === routing.home,
              [classes.headerWrapper2]: activeLink !== routing.home,
            })}
          >
            <img
              src={Logo}
              alt='logo'
              onClick={() => navigate(routing.home)}
              className={cx(
                { [classes.logo]: activeLink === routing.home },
                {
                  [classes.logo2]: activeLink !== routing.home,
                }
              )}
            />
            <nav>
              <List className={classes.listItem}>
                {menu.map((item, index) => (
                  <Link
                    to={item.path}
                    key={index}
                    className={classes.link}
                    onClick={() => setActiveLink(item.path)}
                  >
                    <ListItem
                      className={cx(
                        {
                          [classes.activeLink2]:
                            activeLink !== routing.home &&
                            item.name &&
                            activeLink === item.path,
                        },
                        {
                          [classes.activeLink]:
                            activeLink === item.name ||
                            activeLink === item.path,
                        }
                      )}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </nav>
          </header>
        )}

        {activeLink === routing.home && <SearchProperties />}
      </Box>
      {!isNonMobile && <MobileHeaderMenu />}
    </>
  );
};

export default Header;
