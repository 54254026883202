import { FC, useEffect, Suspense } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as utils from './utils';
import { Loader } from 'src/components/';
import PublicRouter from './router/PublicRouter';
import { ScrollTop } from './constants';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme, StyledEngineProvider } from '@mui/material/styles';

const App: FC = () => {
  const theme = createTheme(utils.theme.theme as any);

  const appHeight = () => {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
  };

  useEffect(() => {
    appHeight();
    window.addEventListener('resize', appHeight);
    return () => window.removeEventListener('resize', appHeight);
  }, []);

  return (
    <BrowserRouter>
      <ScrollTop>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path='*' element={<PublicRouter />} />
              </Routes>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </ScrollTop>
    </BrowserRouter>
  );
};

export default App;
