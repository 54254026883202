import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  sliderContainer: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: '2rem',
    padding: '0 .7rem',
  },

  imgWrapper: {
    position: 'relative',
    marginBottom: '1rem',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.3)',
      zIndex: 1,
    },

    '&:hover': {
      '& $propertyImg': {
        opacity: '0.9',
      },
    },
  },

  propertyImg: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
  },

  screenWrapper: {
    height: '100vh',
    width: '100%',
  },

  btnCard: {
    position: 'absolute',
    display: 'flex',
    top: '5%',
    justifyContent: 'space-between',
    padding: '0 .6rem',
    width: '100%',
    zIndex: 2,
  },

  btnFeatured: {
    '& > button': {
      backgroundColor: theme.palette.primary.main,
      padding: '.1rem .5rem',
      color: theme.palette.background.paper,
      fontSize: '0.8rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: '0.8',
        boxShadow: 'none',
      },
    },
  },
  tagsWrapper: {
    display: 'flex',
    gap: '.6rem',
    marginLeft: 'auto',
    '& > button': {
      backgroundColor: theme.palette.grey[500],
      padding: '.1rem .5rem',
      color: theme.palette.background.paper,
      fontSize: '0.8rem',
      fontWeight: 500,
      textTransform: 'capitalize',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
        opacity: '0.8',
        boxShadow: 'none',
      },
    },
  },

  priceWrapper: {
    position: 'absolute',
    bottom: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 .6rem',
    zIndex: 2,
    '&  p': {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: theme.palette.background.paper,
    },
  },

  cardInfo: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
    '& h4': {
      fontSize: '1.1rem',
      fontWeight: 700,
      color: theme.palette.black[100],
      marginBottom: '.6rem',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minHeight: '3rem',
      '&:hover': {
        color: theme.palette.lightBlue.dark,
      },
    },

    '& p:nth-child(2)': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.black[100],
      marginBottom: '1rem',
    },

    '& p:nth-child(3)': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.black[100],
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));
