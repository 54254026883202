import { FC, useState } from 'react';
import { TeamsInformaiton } from 'src/components/modules/About';
import { Image } from 'src/components';
import Fade from 'react-reveal/Fade';
import { Seo } from 'src/constants';
import group from 'src/assets/group.jpeg';
import group2 from 'src/assets/group.webp';
import mrsMaryann from 'src/assets/mr.jpeg';
import mrsMaryann2 from 'src/assets/mr.webp';
import oseni from 'src/assets/os.jpeg';
import oseni2 from 'src/assets/os.webp';
import tolulope from 'src/assets/to.jpeg';
import tolulope2 from 'src/assets/to.webp';
import workers from 'src/assets/workers.jpeg';
import workers2 from 'src/assets/workers.webp';
import team from 'src/assets/team.jpeg';
import team2 from 'src/assets/team.webp';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './AboutStyles';

const information = [
  {
    name: 'Olalekan  Oseni',
    position: 'Managing Director Frontier Homes',
    img: oseni,
    imgWebp: oseni2,
  },
  {
    name: 'Tolulope Adekunle',
    position: 'Executive Director Frontier Homes',
    img: tolulope,
    imgWebp: tolulope2,
  },
  {
    name: 'Maryann Opara',
    position: 'Head of Sales Frontier Homes',
    img: mrsMaryann,
    imgWebp: mrsMaryann2,
  },
  {
    name: ' Executive Leader',
    position: '',
    img: workers,
    imgWebp: workers2,
  },
  {
    name: 'Members of the Staff',
    position: '',
    img: team,
    imgWebp: team2,
  },
];

const About: FC = () => {
  const classes = useStyles();
  const [hover, setHover] = useState<{ [key: number]: boolean }>({});

  const onMouseOver = (e: any, index: number) => {
    setHover((currentHover) => ({ ...currentHover, [index]: true }));
  };

  const onMouseOut = (e: any, index: number) => {
    setHover((currentHover) => ({ ...currentHover, [index]: false }));
  };

  return (
    <>
      <Seo title='About' />
      <Box className={classes.aboutContainer}></Box>

      <section className={classes.section}>
        <Box className={classes.frontierWrapper}>
          <Fade bottom cascade>
            <Typography className={classes.frontierTitle} variant='h2'>
              ABOUT US
            </Typography>
          </Fade>

          <Fade left>
            <article>
              <Typography variant='body2'>
                Frontier Commercial Services Limited the owners of Frontier
                Homes is a leading global business with its major focus on real
                estate and financial investments.
              </Typography>
              <br />
              <Typography variant='body2'>
                We are committed and dedicated to helping people create wealth
                through real estate in Nigeria. We simplify the process of
                buying and selling landed properties and also ensure that our
                estates are located in areas that are projected for high
                yielding returns.
              </Typography>
              <br />
              <Typography variant='body2'>
                We only provide and deal in locations that are completely free
                from Government acquisition and ligitation. We go an extra mile
                to ensure that we do our due diligence so our clients do not
                have to worry about anything.
              </Typography>
            </article>
          </Fade>
        </Box>

        <Box className={classes.objectivesWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Image
                src={group}
                alt='building'
                fallback={group2}
                className={classes.objectivesImage}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <Typography variant='h3'>our mission</Typography>
                <Typography variant='body2'>
                  To provide financial and empowerment opportunities while also
                  creating business opportunities to solve the problem of
                  unemployment.
                </Typography>
              </Box>

              <Box>
                <Typography variant='h3'>our vision</Typography>
                <Typography variant='body2'>
                  To become a top global institution and people empowerment
                  company.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.meetUsWrapper}>
          <Box className={classes.team}>
            <Fade bottom cascade>
              <Typography className={classes.meetTitle} variant='h2'>
                meet our team
              </Typography>
            </Fade>
          </Box>
          <Grid container spacing={2}>
            {information.map((item, index) => (
              <TeamsInformaiton
                key={index}
                item={item}
                index={index}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                hover={hover}
              />
            ))}
          </Grid>
        </Box>
      </section>
    </>
  );
};

export default About;
