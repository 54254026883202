import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  image: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      objectFit: 'cover',
      height: 'auto',
    },
  },
}));
