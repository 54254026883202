import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    backgroundColor: theme.palette.grey[100],
  },
  blogWrapper: {
    padding: '3rem 3rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 1.5rem',
    },
  },
  categoriesTitle: {
    fontSize: '1.7rem',
    fontWeight: 700,
    marginBottom: '1rem',
    textTransform: 'capitalize',
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 0rem 2rem',
  },

  blogTitleLink: {
    textDecoration: 'none',
    color: ' #263238',
  },

  titleNav: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },

  category: {
    color: theme.palette.grey[600],
    textTransform: 'capitalize',
  },
}));
