import { FC, Fragment } from 'react';
import { SelectField } from 'src/components/Forms';
import { Loader } from 'src/components';
import cx from 'classnames';
import GridColumn from './GridColumn';
import GridRow from './GridRow';
import { Box, Typography, useMediaQuery, Grid } from '@mui/material';
import { useStyles } from './PropertiesCardStyles';

interface IPropertiesCardProps {
  gridType?: string;
  properties: Record<string, any>[];
  isLoading: boolean;
  setSort: (value: string) => void;
  sort: string;
  category?: string | null;
  name?: string | null;
}

const PropertiesCard: FC<IPropertiesCardProps> = ({
  gridType,
  properties,
  isLoading,
  sort,
  setSort,
  category,
  name,
}) => {
  const classes = useStyles();
  const isNonMobile = useMediaQuery('(max-width: 430px)');

  return (
    <>
      <Box
        className={cx(classes.propertiesNumber, {
          [classes.propertiesNumberMobile]: isNonMobile,
        })}
      >
        {category || name ? (
          <Typography variant='h2'>
            {properties && properties.length}
            {properties && properties.length === 1
              ? ' Result Found'
              : ' Results Found'}
          </Typography>
        ) : (
          <Typography variant='h2'>
            {properties && properties.length}
            {properties && properties.length === 1
              ? ' Property'
              : ' Properties'}
          </Typography>
        )}
        <Box className={classes.selectWrapper}>
          <Typography variant='h2'>Sort By:</Typography>
          <SelectField
            value={sort}
            onChange={(e: any) => setSort(e.target.value)}
            name='sort'
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            options={[
              { _id: 'Newest', value: 'Newest' },
              { _id: 'Oldest', value: 'Oldest' },
              { _id: 'Price: Low to High', value: 'Price: Low to High' },
              { _id: 'Price: High to Low', value: 'Price: High to Low' },
            ]}
            className={classes.select}
          />
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
        {isLoading ? (
          <Loader />
        ) : properties && properties.length !== 0 ? (
          properties.map((property) => {
            return (
              <Fragment key={property._id}>
                {gridType === 'column-grid' ? (
                  <GridColumn property={property} />
                ) : (
                  <GridRow property={property} />
                )}
              </Fragment>
            );
          })
        ) : (
          <Typography className={classes.notFound} variant='h2'>
            No Properties Found
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default PropertiesCard;
