import { FC } from 'react';
import RelatedItems from './RelatedItems';
import { Box, Typography, Grid } from '@mui/material';
import { useStyles } from './BlogRelatedPostsStyles';

interface IBlogRelatedPosts {
  relatedPosts: Record<string, any>[];
}
const BlogRelatedPosts: FC<IBlogRelatedPosts> = ({ relatedPosts }) => {
  const classes = useStyles();
  return (
    <Box className={classes.relatedPostWrapper}>
      <Typography className={classes.title} variant='h2'>
        Related Posts
      </Typography>
      <Grid container spacing={2}>
        {relatedPosts &&
          relatedPosts.map((relatedPosts) => {
            return (
              <RelatedItems
                key={relatedPosts._id}
                relatedPosts={relatedPosts}
              />
            );
          })}
      </Grid>
    </Box>
  );
};

export default BlogRelatedPosts;
