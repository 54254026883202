import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  searchWrapper: {
    marginBottom: '2.5rem',
  },
  searchTitle: {
    fontSize: '1.5rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: '1rem',
    color: theme.palette.black[100],
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  input: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  btnSearch: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    padding: '0.5rem 1.5rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  recentWrapper: {
    marginBottom: '2.5rem',
  },

  recentDescription: {
    display: 'flex',
    gap: '.1rem',
    padding: '.5rem 0rem ',
    '& p': {
      fontSize: '1rem',
      fontWeight: 400,
      color: theme.palette.primary.grey,
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  blogTitleLink: {
    textDecoration: 'none',
    color: '#263238',
  },
}));
