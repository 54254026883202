import { FC } from 'react';
import PropertyCardItem from './PropertyCardItem';
import { Loader } from 'src/components';
import { FeaturedSlider } from 'src/components/Slider';
import { Grid } from '@mui/material';
import { useStyles } from './CardBoxStyles';

interface ICardBox {
  properties: Record<string, any>[];
  isLoading: boolean;
}

const CardBox: FC<ICardBox> = ({ properties, isLoading }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.sliderContainer}>
      <FeaturedSlider>
        {isLoading ? (
          <Loader />
        ) : (
          properties
            .slice(0, 9)
            .map((property) => (
              <PropertyCardItem key={property._id} property={property} />
            ))
        )}
      </FeaturedSlider>
    </Grid>
  );
};

export default CardBox;
