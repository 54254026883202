import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    backgroundColor: theme.palette.grey[100],
  },

  container: {
    padding: '3rem 3rem',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 1.5rem',
    },
  },

  propertiesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    '& h2': {
      fontSize: '1.7rem',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
  },

  gridIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: theme.palette.grey[700],
  },

  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  breadcrumbs: {
    color: theme.palette.lightBlue.dark,
  },
}));
