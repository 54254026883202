import { FC } from 'react';
import { useStyles } from '../BannerStyles';

interface ICarouselImage {
  banner: Record<string, any>;
  i: number;
  setImageViewer: (imageViewer: any) => void;

  image: {
    banner: {
      url: string;
    };
  };
}

const CarouselImage: FC<ICarouselImage> = ({
  i,
  image,
  setImageViewer,
  banner,
}) => {
  const classes = useStyles();
  return (
    <img
      key={i}
      src={image?.banner?.url}
      className={classes.image}
      alt={`${i} Slide`}
      onClick={() => {
        if (banner.length) {
          setImageViewer({
            show: true,
            currentImg: 0,
          });
        }
      }}
    />
  );
};

export default CarouselImage;
