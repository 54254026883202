export const sendContactInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

export const sendScheduleInitialValues = {
  tourType: '',
  date: '',
  time: '',
  name: '',
  phone: '',
  email: '',
  message: '',
  terms: false,
};

export const sendClientInitialValues = {
  name: '',
  phone: '',
  email: '',
  message: '',
  client: '',
  terms: false,
};

export const sendLocationInitialValues = {
  name: '',
  phone: '',
  email: '',
  location: '',
  howSoon: '',
};
