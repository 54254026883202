import React, { FC } from 'react';
import triangleLeft from '../assets/icons/triangle-arrow-left.svg';
import triangleRight from '../assets/icons/triangle-arrow-right.svg';
import { Box } from '@mui/material';

interface IChangeSettings {
  currentSlide: number;
  slideCount: number;
}

const ChangingArrowLeft: FC<IChangeSettings> = ({
  currentSlide,
  slideCount,
  ...props
}) => (
  <Box {...props}>
    <img
      className={'slider_arrows_container_arrival slider_arrow_left_arrival'}
      src={triangleLeft}
      alt='triangle-left'
    />
  </Box>
);

const ChangingArrowRight: FC<IChangeSettings> = ({
  currentSlide,
  slideCount,
  ...props
}) => (
  <Box {...props}>
    <img
      src={triangleRight}
      className={'slider_arrows_container_arrival slider_arrow_right_arrival'}
      alt='triangle-left'
    />
  </Box>
);

export const ChangeSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '45px',
      },
    },
  ],

  prevArrow: <ChangingArrowLeft currentSlide={0} slideCount={0} />,
  nextArrow: <ChangingArrowRight currentSlide={0} slideCount={0} />,
};

export const FeaturedSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],

  prevArrow: <ChangingArrowLeft currentSlide={0} slideCount={0} />,
  nextArrow: <ChangingArrowRight currentSlide={0} slideCount={0} />,
};
