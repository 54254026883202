import React, {
  FC,
  ButtonHTMLAttributes,
  ReactNode,
  MouseEventHandler,
} from 'react';
import { always } from 'src/utils';
import { Button } from '@mui/material';

interface IActionButton {
  children: ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  name?: HTMLButtonElement['name'];
  value?: HTMLButtonElement['value'];
  disabled?: HTMLButtonElement['disabled'];
  className?: HTMLButtonElement['className'];
  variant?: 'contained' | 'outlined';
}

const ActionButton: FC<IActionButton> = ({
  onClick = always.EMPTY_FUNC,
  value,
  name,
  disabled,
  className,
  children = 'Default text',
  type = 'button',
  variant = 'contained',
}) => {
  return (
    <Button
      onClick={onClick}
      value={value}
      name={name}
      disabled={disabled}
      className={className}
      type={type}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
