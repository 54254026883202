import { FC } from 'react';
import {
  Featured,
  Properties,
  Testimonials,
  Expectations,
  Banner,
} from 'src/components/modules/Home';
import { Seo } from 'src/constants';
import { Box } from '@mui/material';
import { useStyles } from './HomeStyles';

const Home: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.homeWrapper}>
      <Seo title='Home' />
      <Banner />
      <Featured />
      <Expectations />
      <Properties />
      <Testimonials />
    </Box>
  );
};

export default Home;
