import { FC, useEffect, useState } from 'react';
import { Loader } from 'src/components';
import ImgsViewer from 'react-images-viewer';
import * as service from 'src/service';
import Carousel from 'react-material-ui-carousel';
import CarouselImage from './CarouselImage';

const Banner: FC = () => {
  const [banner, setBanner] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageViewer, setImageViewer] = useState({
    show: false,
    currentImg: 0,
  });

  const prevImage = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      currentImg: prevState.currentImg - 1,
    }));
  };

  const nextImage = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      currentImg: prevState.currentImg + 1,
    }));
  };

  const closeImageViewer = () => {
    setImageViewer((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    service.home
      .getBanners()
      .then((res) => {
        setBanner(res.data.banner);
        setIsLoading(false);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {banner.length !== 0 ? (
        banner.length > 1 ? (
          <Carousel>
            {banner.map((image: any, i: number) => {
              return (
                <CarouselImage
                  key={i}
                  image={image}
                  i={i}
                  setImageViewer={setImageViewer}
                  banner={banner}
                />
              );
            })}
          </Carousel>
        ) : (
          banner.map((image: any, i: number) => (
            <CarouselImage
              key={i}
              image={image}
              i={i}
              setImageViewer={setImageViewer}
              banner={banner}
            />
          ))
        )
      ) : null}

      {banner?.length > 1 && (
        <ImgsViewer
          imgs={banner.map((image: any) => {
            return {
              src: image?.banner?.url,
            };
          })}
          backdropCloseable={true}
          currImg={imageViewer.currentImg}
          isOpen={imageViewer.show}
          onClickPrev={prevImage}
          onClickNext={nextImage}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default Banner;
