import { FC, useState, useEffect } from 'react';
import * as service from 'src/service';
import { Seo } from 'src/constants';
import { useActivePage, useSort } from 'src/components';
import Paginations from 'src/constants/Paginations';
import { PropertiesCard, PropertiesCardFeatures } from 'src/components/CardBox';
import PropertiesGridIcon from './PropertiesGridIcon';
import { Box, Typography, Grid } from '@mui/material';
import { useStyles } from './PropertiesStyles';

const Properties: FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { pageNumbers, setPageNumbers, pageLoaded } = useActivePage();
  const { properties, setProperties, sort, setSort, onSort } = useSort();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [activeGrid, setActiveGrid] = useState('column-grid');

  useEffect(() => {
    if (pageLoaded) {
      service.property
        .getAllProperties(pageNumbers, 10)
        .then((res) => {
          setProperties(res.data.properties);
          setTotalPages(res.data.total);
          setIsLoading(false);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [pageLoaded, pageNumbers, setProperties]);

  return (
    <section className={classes.section}>
      <Seo title='Properties' />
      <Box className={classes.container}>
        <Box className={classes.propertiesWrapper}>
          <Typography variant='h2'>Properties</Typography>
          <PropertiesGridIcon
            activeGrid={activeGrid}
            setActiveGrid={setActiveGrid}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <PropertiesCard
              gridType={activeGrid}
              properties={onSort}
              isLoading={isLoading}
              setSort={setSort}
              sort={sort}
            />
            {totalPages > 1 && properties.length && (
              <Box className={classes.paginationWrapper}>
                <Paginations
                  pageNumbers={pageNumbers}
                  setPageNumbers={setPageNumbers}
                  isTotalPages={totalPages}
                  loading={isLoading}
                  handleNewLoading={() => {
                    setIsLoading(true);
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <PropertiesCardFeatures />
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default Properties;
