import * as Yup from 'yup';

export const contactSchema = Yup.object().shape({
  firstName: Yup.string().required('first name is required'),
  lastName: Yup.string().required('last name is required'),
  email: Yup.string().email('Invalid email').required('email is required'),
  message: Yup.string().required('message is required'),
});

export const scheduleSchema = Yup.object().shape({
  tourType: Yup.string().required('tourType is required'),
  date: Yup.string().required('date is required'),
  time: Yup.string().required('time is required'),
  name: Yup.string().required('name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('phone is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  message: Yup.string().required('Message is required'),
  terms: Yup.boolean().oneOf([true], 'must accept terms and conditions'),
});

export const clientSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('phone is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  message: Yup.string().required('Message is required'),
  client: Yup.string().required('type is required'),
  terms: Yup.boolean().oneOf([true], 'must accept terms and conditions'),
});

export const locationSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('phone is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  location: Yup.string().required('location is required'),
  howSoon: Yup.string().required('duration is required'),
});
