export const home = '/';
export const about = '/about';
export const properties = '/properties';
export const blog = '/blog';
export const contact = '/contact';
export const blogCategory = '/blog/category';
export const blogTag = '/blog/tag';
export const blogUser = '/blog/user';
export const blogSearch = '/search/blog';
export const property = '/property';
export const propertyCities = '/property/cities';
export const propertyTag = '/property/tag';
export const propertyUser = '/property/user';
export const propertySearch = '/search/property';
export const propertyType = '/property/type';
export const propertyStatus = '/property/status';
export const propertyName = '/property/name';
