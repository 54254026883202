import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '630px',
    padding: '1rem 3rem ',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '1rem 1.5rem',
    },
  },

  title: {
    fontSize: '2rem',
    fontWeight: 700,
    color: theme.palette.black[100],
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '2rem',
    },
  },

  titleMobile: {
    fontSize: '2rem',
    fontWeight: 700,
    color: theme.palette.black[100],
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '2rem',
    },
  },

  titleMobileT: {
    fontSize: '2rem',
    fontWeight: 700,
    color: theme.palette.black[100],
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '2rem',
    },
  },
}));
