import { FC } from 'react';
import { useToggle } from 'src/components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useStyles } from './ChatBoxStyles';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ChatBox: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useToggle(false);

  const onOpenChat = () => {
    setTimeout(() => {
      setOpen.on();
    }, 2000);
  };
  const onCloseChat = () => setOpen.off();
  return (
    <>
      <CustomWidthTooltip title='Need Help?' placement='left'>
        <Box className={classes.chatWrapper}>
          <WhatsAppIcon
            onMouseEnter={onOpenChat}
            style={{ color: 'white', fontSize: '40px', cursor: 'pointer' }}
          />
        </Box>
      </CustomWidthTooltip>

      {open && (
        <Box className={classes.chatboxBg}>
          <Card sx={{ borderRadius: '20px' }}>
            <CardContent className={classes.content}>
              <Box className={classes.frontierWrapper}>
                <Typography variant='h2'> Frontier Homes</Typography>
                <CloseIcon
                  onClick={onCloseChat}
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                />
              </Box>
              <Box className={classes.help}>
                <Card sx={{ borderRadius: '20px' }}>
                  <CardContent className={classes.helpContent}>
                    <Typography variant='body2'>
                      Welcome to our customer's support
                    </Typography>
                    <Typography variant='body2'>
                      How can we assist you?
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              <a
                href='https://wa.me/2348082869977'
                className={classes.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Button
                  className={classes.btnSend}
                  variant='contained'
                  startIcon={<SendIcon />}
                >
                  open chat
                </Button>
              </a>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default ChatBox;
