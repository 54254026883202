import { get } from './api';

export const getAllProperties = (page?: number, limit?: number) =>
  get({ url: `/properties?page=${page}&limit=${limit}` });

export const getPropertyTypes = () => {
  return get({ url: `/properties/types` });
};

export const getAllCategories = () => {
  return get({ url: `/property/categories` });
};
export const getAllPropertyNames = () => {
  return get({ url: `/properties/names` });
};

export const getPropertyBySlug = (slug: string) =>
  get({ url: `/property/${slug}` });

export const getPropertyCategory = (
  category: string,
  page: number,
  limit: number
) => {
  return get({
    url: `/property/category/${category}?page=${page}&limit=${limit}`,
  });
};

export const getPropertyTags = (tag: string, page: number, limit: number) => {
  return get({ url: `/property/tag/${tag}?page=${page}&limit=${limit}` });
};
export const getPropertyUser = (user: string, page: number, limit: number) => {
  return get({ url: `/property/user/${user}?page=${page}&limit=${limit}` });
};

export const getPropertyFeatured = () => {
  return get({ url: `/properties/featured` });
};

export const getPropertyType = (type: string, page: number, limit: number) => {
  return get({ url: `/property/type/${type}?page=${page}&limit=${limit}` });
};

export const getPropertyStatus = (
  status: string,
  page: number,
  limit: number
) => {
  return get({ url: `/property/status/${status}?page=${page}&limit=${limit}` });
};

export const getPropertyName = (name: string, page: number, limit: number) => {
  return get({ url: `/property/name/${name}?page=${page}&limit=${limit}` });
};
export const searchProperty = (query: any) =>
  //eslint-disable-next-line
  get({ url: '/search/property' + '?' + new URLSearchParams(query) });
