import React, { FC } from 'react';
import { timeOptions } from './data';
import * as service from 'src/service';
import { scheduleSchema } from 'src/utils/validation';
import { sendScheduleInitialValues } from 'src/utils/values';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import {
  SelectField,
  ActionButton,
  InputField,
  InputLabel,
  TextAreaField,
} from 'src/components/Forms';
import { Form, Formik, FormikValues } from 'formik';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Checkbox,
} from '@mui/material';
import { useStyles } from './ScheduleStyles';
const Schedule: FC = () => {
  const classes = useStyles();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();

  const onSendSchedule = (values: FormikValues) => {
    service.contact.sendSchedule(values).then((res) => {
      if (res.response.status === 200) {
        changeContinuePopup('Your message has been sent successfully');
        setTimeout(() => {
          changeContinuePopup('Your message has been sent successfully ');
        }, 3000);
      }
    });
  };

  return (
    <Box className={classes.schedule}>
      {changingConfirmed && (
        <ChangeConfirmedPopup
          successText={successText}
          changingConfirmed={changingConfirmed}
        />
      )}
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Typography variant='h2'>Schedule Inspection</Typography>
          <Formik
            initialValues={sendScheduleInitialValues}
            validationSchema={scheduleSchema}
            onSubmit={(values: FormikValues, actions) => {
              onSendSchedule(values);
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              handleChange,
              errors,
              touched,
              setFieldValue,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.inputWrapper}>
                      <InputLabel title=' Tour type' />
                      <SelectField
                        name='tourType'
                        options={[
                          { _id: 'in person', value: 'in person' },
                          { _id: 'video chat', value: 'video chat' },
                        ]}
                        placeholder='select tour type...'
                        onChange={(e) =>
                          setFieldValue('tourType', e.target.value)
                        }
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        value={values.tourType}
                        className={
                          touched.tourType && errors.tourType
                            ? classes.error
                            : classes.selectField
                        }
                      />

                      {touched.tourType && errors.tourType && (
                        <Box className={classes.errorText}>
                          {errors.tourType as string}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.inputWrapper}>
                      <InputLabel title=' Date' />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={values.date as Dayjs}
                          onChange={(newValue) => {
                            setFieldValue('date', newValue);
                          }}
                          format='DD/MM/YYYY'
                          className={
                            touched.date && errors.date ? classes.error : ''
                          }
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </LocalizationProvider>

                      {touched.date && errors.date && (
                        <Box className={classes.errorText}>
                          {errors.date as string}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.inputWrapper}>
                      <InputLabel title=' Time' />
                      <SelectField
                        name='time'
                        options={timeOptions.map((time) => time)}
                        placeholder='select time...'
                        onChange={(e) => setFieldValue('time', e.target.value)}
                        value={values.time}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className={
                          touched.time && errors.time
                            ? classes.error
                            : classes.selectField
                        }
                      />
                      {touched.time && errors.time && (
                        <Box className={classes.errorText}>
                          {errors.time as string}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.inputWrapper}>
                      <InputLabel title=' Name' />
                      <InputField
                        placeholder='enter your name...'
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.inputWrapper}>
                      <InputLabel title=' Phone' />
                      <InputField
                        placeholder='enter your phone...'
                        name='phone'
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.inputWrapper}>
                      <InputLabel title=' Email' />
                      <InputField
                        placeholder='enter your email...'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className={classes.inputWrapper}>
                      <InputLabel title=' Message' />
                      <TextAreaField
                        placeholder='enter your message...'
                        name='message'
                        minRows={8}
                        maxRows={8}
                        value={values.message}
                        onChange={handleChange}
                        className={
                          touched.message && errors.message
                            ? classes.error
                            : classes.textArea
                        }
                      />
                    </Box>

                    {touched.message && errors.message && (
                      <Box className={classes.errorText}>
                        {errors.message as string}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Box className={classes.checkboxWrapper}>
                  <Checkbox
                    checked={values.terms}
                    onChange={(e) => setFieldValue('terms', e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography variant='body1'>
                    By Submitting this form, you agree to &nbsp;
                    <span className={classes.termUse}>Terms of Use</span>
                  </Typography>
                  {touched.terms && errors.terms && (
                    <Box className={classes.errorText}>
                      {errors.terms as string}
                    </Box>
                  )}
                </Box>

                <ActionButton
                  type='submit'
                  disabled={isSubmitting || !values.terms}
                  className={classes.btnSchedule}
                >
                  Submit a Tour Request
                </ActionButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Schedule;
