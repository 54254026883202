import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  detailsImg: {
    width: '100%',
    height: '500px',
    objectFit: 'cover',
    marginBottom: '2rem',
  },

  overView: {
    margin: '2rem 0 ',

    '&  h3': {
      fontSize: '1.7rem',
      fontWeight: 700,
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },

  propertyType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '1rem',
    '& > p:nth-child(1)': {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    '& > p:nth-child(2)': {
      fontSize: '1.2rem',
      fontWeight: 300,
      marginLeft: '4rem',
      color: theme.palette.grey[700],
    },
  },

  propertySize: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > p:nth-child(1)': {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    '& > p:nth-child(2)': {
      fontSize: '1.2rem',
      fontWeight: 300,
      marginLeft: '4rem',
      color: theme.palette.grey[700],
    },
  },

  descriptionWrapper: {
    marginBottom: '2rem',
  },

  content: {
    ' & > h3': {
      fontSize: '1.7rem',
      fontWeight: 700,
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& > p:nth-child(2)': {
      fontSize: '1rem',
      fontWeight: 300,
      marginBottom: '.5rem',
      color: theme.palette.grey[700],
    },
    '& > p:nth-child(3)': {
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: '.5rem',
      color: theme.palette.text.primary,
    },
    '& > p:nth-child(4)': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.grey[700],
    },
  },
  locationWrapper: {
    marginBottom: '2rem',
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    gap: '1rem',
    '& > p:nth-child(1)': {
      fontSize: '1rem',
      fontWeight: 700,
    },
    '& > p:nth-child(2)': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.grey[700],
    },
  },

  detailsWrapper: {
    marginBottom: '2rem',
  },

  detailsInfo: {
    backgroundColor: theme.palette.primary.main,
    padding: '2rem',
    color: theme.palette.grey[100],
    borderRadius: '1rem',
    marginBottom: '2rem',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '3rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '.4rem',
      marginBottom: '1rem',
    },
    '& > h3': {
      fontSize: '1.7rem',
      fontWeight: 700,
    },
  },
  propertiesDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '1rem',
    '& > p:nth-child(1)': {
      fontSize: '1rem',
      fontWeight: 700,
    },
    '& > p:nth-child(2)': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.white.main,
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  timePosted: {
    display: 'flex',
    alignItems: 'center',
    gap: '.4rem',
    '& > p': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.black[100],
    },
  },
  additionalDetailsWrapper: {
    marginBottom: '2rem',
    '& > h3': {
      fontSize: '1.2rem',
      fontWeight: 700,
      marginBottom: '1rem',
      color: theme.palette.black[100],
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },

  additionalDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    marginBottom: '.9rem',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '.4rem',
    },
    '& > p:nth-child(1)': {
      fontSize: '.9rem',
      fontWeight: 700,
      color: theme.palette.black[100],
      minWidth: 150,
    },
    '& > p:nth-child(2)': {
      fontSize: '.9rem',
      fontWeight: 300,
      color: theme.palette.grey[700],
    },
  },
}));
