import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    backgroundColor: theme.palette.grey[100],
  },

  serviceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3rem',
    padding: '3rem 3rem',
    [theme.breakpoints.down('md')]: {
      padding: '3rem 1.5rem',
    },
  },

  title: {
    fontSize: '2.5rem',
    fontWeight: 700,
    color: theme.palette.black[100],
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '2rem',
    },
  },

  description: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.black[100],
    marginBottom: '2rem',
    maxWidth: '60%',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },

  coreValues: {
    color: theme.palette.grey[700],
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '1rem',
  },

  link: {
    textDecoration: 'none',
  },

  btnLearnMore: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    marginTop: '1.5rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  card: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    '& > h4': {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: theme.palette.black[100],
      textAlign: 'center',
      marginBottom: '1rem',
    },

    '& > p': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.black[100],
      textAlign: 'center',
    },
  },

  cardImg: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
  },
}));
