import pic from '../../assets/pic.webp';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  aboutContainer: {
    backgroundImage: `url(${pic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50vh',
    width: '100%',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '50vh',
      background: 'rgba(0,0,0,0.3)',
    },
  },

  aboutWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    position: 'relative',
    zIndex: 1,
  },

  mainTitle: {
    color: theme.palette.background.paper,
    fontSize: '4rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  section: {
    padding: '2rem 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 1.5rem',
    },
  },

  frontierWrapper: {
    marginBottom: '4rem',

    '& p': {
      fontSize: '1rem',
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  },

  frontierTitle: {
    fontSize: '2rem',
    fontWeight: 700,
    marginBottom: '2rem',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  objectivesWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 .5rem',
    },

    '& h2': {
      fontSize: '1.3rem',
      fontWeight: 700,
      color: theme.palette.lightBlue.dark,
      textTransform: 'uppercase',
      margin: '3rem 0 1rem',
    },

    '& h3': {
      fontSize: '1.3rem',
      fontWeight: 700,
      color: theme.palette.primary.dark,
      textTransform: 'uppercase',
      marginBottom: '1rem',
    },

    '& p': {
      fontSize: '1rem',
      fontWeight: 400,
      color: theme.palette.text.primary,
      marginBottom: '1.5rem',
    },
  },

  objectivesImage: {
    maxWidth: '100%',
    height: '350px',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },

  meetUsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '4rem',
    width: '100%',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.grey[100],
      width: '100%',
      minHeight: '1422px',
      zIndex: -1,
      [theme.breakpoints.down('sm')]: {
        minHeight: '2000px',
      },
    },
  },

  team: {
    margin: '2rem 0 2rem',
  },

  meetTitle: {
    fontSize: '2rem',
    fontWeight: 700,
    marginBottom: '0.6rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  meetDescription: {
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },

  card: {
    maxWidth: '450px',
    boxShadow: 'none',
    margin: '0 auto',
  },
  content: {
    position: 'relative',
    padding: '0',
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  teamName: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '30px',
    left: '0',
    width: '100%',
    padding: '1rem',
    zIndex: 1,
    color: theme.palette.background.paper,
    '& h3': {
      fontSize: '1.3rem',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '.9rem',
      fontWeight: 400,
      textAlign: 'center',
    },
  },

  imgWrapper: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.3)',
      zIndex: 1,
    },
    '& > img': {
      width: '100%',
      height: '400px',
      objectFit: 'cover',
    },
  },

  teamoverLay: {
    position: 'absolute',
    bottom: '0px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    height: '100%',
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255,255,255, 0.9)',
    '& h3': {
      fontSize: '1.3rem',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '.9rem',
      fontWeight: 400,
      textAlign: 'center',
      marginBottom: '1rem',
    },
  },
  socialIcons: {
    display: 'flex',
    zIndex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    color: theme.palette.lightBlue.dark,
  },
}));
