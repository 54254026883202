import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { routing } from 'src/utils';
import * as service from 'src/service';
import * as links from 'src/constants/links';
import logo4 from 'src/assets/logo4.png';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useStyles } from './FooterStyles';

const menu = [
  {
    name: 'Home',
    path: routing.home,
  },
  {
    name: 'About',
    path: routing.about,
  },
  {
    name: 'Properties',
    path: routing.properties,
  },
  {
    name: 'Blog',
    path: routing.blog,
  },
  {
    name: 'Contact',
    path: routing.contact,
  },
];

const Footer: FC = () => {
  const classes = useStyles();
  const isNonMobile = useMediaQuery(useTheme().breakpoints.up('sm'));
  const [propertyName, setPropertyName] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    service.property
      .getAllPropertyNames()
      .then((res) => {
        setPropertyName(res.data.properties);
      })
      .catch(console.error);
  }, []);

  return (
    <footer className={classes.footer}>
      <Grid container spacing={2} className={classes.gridLine}>
        <Grid item xs={12} sm={6} md={3}>
          <img src={logo4} alt='logo' className={classes.footerImg} />

          {isNonMobile && (
            <Box className={classes.socialMedia}>
              <a href={links.facebook} target='_blank' rel='noreferrer'>
                <FacebookIcon sx={{ color: '#fff' }} />
              </a>
              <a href={links.instagram} target='_blank' rel='noreferrer'>
                <InstagramIcon sx={{ color: '#fff' }} />
              </a>
              <a href={links.twitter} target='_blank' rel='noreferrer'>
                <TwitterIcon sx={{ color: '#fff' }} />
              </a>
            </Box>
          )}
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <Box className={classes.discoverWrapper}>
            <Typography variant='h4'>Discover</Typography>
            <List>
              {propertyName.slice(0, 6).map((name) => (
                <Link
                  key={name._id}
                  to={`${routing.propertyName}/${name._id}`}
                  className={classes.link}
                >
                  <ListItem>
                    <KeyboardArrowRightIcon />
                    <ListItemText primary={name._id} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Box className={classes.discoverWrapper}>
            <Typography variant='h4'>Quick Link</Typography>
            <List>
              {menu.map((item, index) => (
                <Link to={item.path} key={index} className={classes.link}>
                  <ListItem>
                    <ListItemText primary={item.name} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <Box className={classes.discoverWrapper}>
            <Typography variant='h4'>Contact Us</Typography>
            <List className={classes.list}>
              <ListItem sx={{ gap: '1rem' }}>
                <RoomIcon />
                Head Office: 144, Egbeda-Idimu road, Seliat bus-stop, Egbeda
                Lagos
              </ListItem>
              <ListItem sx={{ gap: '1rem' }}>
                <RoomIcon />
                Ikeja-Branch: 3, Balogun street Anifowose, by Railway crossing
                ikeja, lagos
              </ListItem>

              <ListItem sx={{ gap: '1rem' }}>
                <RoomIcon />
                Ojo/Iba-Branch: 40, Igbo Elerin Road, Martin B/Stop, Okokomaiko,
                ojo Lagos
              </ListItem>

              <ListItem sx={{ gap: '1rem' }}>
                <CallIcon />
                <ListItemText primary='070012FRONTIER' />
              </ListItem>
              <ListItem sx={{ gap: '1rem' }}>
                <MailOutlineIcon />
                <ListItemText primary='admin@frontiergroupltd.com' />
              </ListItem>
            </List>
          </Box>
        </Grid>

        {!isNonMobile && (
          <Grid item xs={6}>
            <Box className={classes.socialMedia}>
              <a href={links.facebook} target='_blank' rel='noreferrer'>
                <FacebookIcon sx={{ color: '#fff' }} />
              </a>
              <a href={links.instagram} target='_blank' rel='noreferrer'>
                <InstagramIcon sx={{ color: '#fff' }} />
              </a>
              <a href={links.twitter} target='_blank' rel='noreferrer'>
                <TwitterIcon sx={{ color: '#fff' }} />
              </a>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box className={classes.newDate}>
        ©{new Date().getFullYear()} Frontier Homes All rights reserved
      </Box>
    </footer>
  );
};

export default Footer;
