import { get } from './api';

export const getAllBlogs = (page?: number, limit?: number) =>
  get({ url: `/blogs?page=${page}&limit=${limit}` });
export const getBlogBySlug = (slug: string) => get({ url: `/blog/${slug}` });
export const getBlogCategory = (
  category: string,
  page: number,
  limit: number
) => {
  return get({ url: `/blog/category/${category}?page=${page}&limit=${limit}` });
};
export const getBlogTags = (tag: string, page: number, limit: number) => {
  return get({ url: `/blog/tag/${tag}?page=${page}&limit=${limit}` });
};
export const getBlogUser = (user: string, page: number, limit: number) => {
  return get({ url: `/blog/user/${user}?page=${page}&limit=${limit}` });
};

export const getAllCategories = () => get({ url: '/blog/categories' });
export const getRelatedPosts = (category: string) =>
  get({ url: `/blogs/related/${category}` });

export const searchBlog = (query: any) =>
  //eslint-disable-next-line
  get({ url: '/search/blog' + '?' + new URLSearchParams(query) });
