import { FC, useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Seo } from 'src/constants';
import { PropertiesCard, PropertiesCardFeatures } from 'src/components/CardBox';
import * as service from 'src/service';
import PropertiesGridIcon from '../PropertiesGridIcon';
import { routing } from 'src/utils';
import { useSort } from 'src/components';
import { Box, Typography, Grid, Breadcrumbs } from '@mui/material';
import { useStyles } from '../PropertiesStyles';

const SearchProperties: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [activeGrid, setActiveGrid] = useState('column-grid');
  const category = new URLSearchParams(location.search).get('cities');
  const name = new URLSearchParams(location.search).get('name');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setProperties, sort, setSort, onSort } = useSort();

  const getProperties = useCallback(
    (query: { name: string | null; category: string | null }) => {
      let params = {};
      if (query.name) {
        params = { name: query.name };
      }
      if (query.category) {
        params = { category: query.category };
      }
      if (query.name && query.category) {
        params = { name: query.name, category: query.category };
      }
      setIsLoading(true);
      service.property
        .searchProperty(params)
        .then((res) => {
          console.log(res.data.properties);
          setProperties(res.data.properties);
          setIsLoading(false);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setProperties]
  );

  useEffect(() => {
    if (category || name) {
      getProperties({ name, category });
    }
  }, [getProperties, name, category]);
  return (
    <section className={classes.section}>
      <Seo title={name ? name : category ? category : 'Search Results'} />
      <Box className={classes.container}>
        <Box sx={{ marginBottom: '1rem' }}>
          <Breadcrumbs separator='›' aria-label='breadcrumb'>
            <Link to={routing.home} className={classes.link}>
              <Typography className={classes.breadcrumbs}>Home</Typography>
            </Link>
            <Typography color='text.primary'>Search Results</Typography>
          </Breadcrumbs>
        </Box>
        <Box className={classes.propertiesWrapper}>
          <Typography variant='h2'>Search Results</Typography>
          <PropertiesGridIcon
            activeGrid={activeGrid}
            setActiveGrid={setActiveGrid}
          />
        </Box>

        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          <Grid item xs={12} sm={12} md={8}>
            <PropertiesCard
              gridType={activeGrid}
              properties={onSort}
              isLoading={isLoading}
              setSort={setSort}
              sort={sort}
              category={category}
              name={name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <PropertiesCardFeatures />
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default SearchProperties;
