import { FC, useEffect, useState } from 'react';
import { useActivePage } from 'src/components';
import { Seo } from 'src/constants';
import Fade from 'react-reveal/Fade';
import Paginations from 'src/constants/Paginations';
import * as service from 'src/service';
import { BlogCard } from 'src/components/modules/Blog';
import { Box, Typography } from '@mui/material';
import { useStyles } from './BlogStyles';

const Blog: FC = () => {
  const classes = useStyles();
  const { pageNumbers, setPageNumbers, pageLoaded } = useActivePage();
  const [blogs, setBlogs] = useState<Record<string, any>[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (pageLoaded) {
      service.blog
        .getAllBlogs(pageNumbers, 9)
        .then((res) => {
          setBlogs(res.data.blogs);
          setTotalPages(res.data.total);
          setIsLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [pageLoaded, pageNumbers]);
  return (
    <>
      <Seo title='Blog' />
      <Box className={classes.blogContainer}>
        <Box className={classes.blogWrapper}>
          <Typography variant='h2'>
            <Fade bottom cascade>
              Our Blog
            </Fade>
          </Typography>
        </Box>
      </Box>
      <section className={classes.section}>
        <BlogCard blogs={blogs} isLoading={isLoading} />
        {totalPages > 1 && blogs.length && (
          <Box className={classes.paginationWrapper}>
            <Paginations
              pageNumbers={pageNumbers}
              setPageNumbers={setPageNumbers}
              isTotalPages={totalPages}
              loading={isLoading}
              handleNewLoading={() => {
                setIsLoading(true);
              }}
            />
          </Box>
        )}
      </section>
    </>
  );
};

export default Blog;
