import React, { FC } from 'react';

interface InPutLabelProps {
  id?: string;
  title: string;
  className?: string;
}

const InputLabel: FC<InPutLabelProps> = ({ id, title, className }) => {
  return (
    <label htmlFor={id} className={className}>
      {title}
    </label>
  );
};

export default InputLabel;
