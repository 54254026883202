import { env } from 'src/utils';
import Keys from 'src/constants/helper';

const NOT_AUTHORIZED_STATUS = 401;

type IFetchParams<D> = {
  url: string;
  baseUrl?: string;
  body?: D;
  parseBody?: (parma: D) => any;
  headers?: HeadersInit;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
};

type IServiceResponse<D> = {
  [x: string]: any;
  error: boolean;
  data: D;
};
type IFetchResponse<R> = { response: Response; data: R };

const _fetch = async <R = any, D = any>({
  url,
  baseUrl = env.REAL_ESTATE_SERVICE,
  body,
  parseBody = JSON.stringify,
  headers = {},
  method,
}: IFetchParams<D>): Promise<IFetchResponse<R>> => {
  const token = localStorage.getItem(Keys.JWT_TOKEN);
  const response = await fetch(`${baseUrl}${url}`, {
    body: body ? parseBody(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      ...headers,
    },
    method,
  });
  if (response.status === NOT_AUTHORIZED_STATUS) {
    localStorage.removeItem(Keys.JWT_TOKEN);
  }
  const data = await response.json();
  return { response, data };
};

const get = <R = any, D = any, RF = IServiceResponse<R>>({
  url,
  headers,
}: Omit<IFetchParams<D>, 'method'>): Promise<IFetchResponse<RF>> =>
  _fetch({ url, headers, method: 'GET' });

const post = <R = any, D = any, RF = IServiceResponse<R>>({
  url,
  body,
  headers,
}: Omit<IFetchParams<D>, 'method'>): Promise<IFetchResponse<RF>> =>
  _fetch({ url, body: body, headers, method: 'POST' });

const put = <R = any, D = any, RF = IServiceResponse<R>>({
  url,
  body,
  headers,
}: Omit<IFetchParams<D>, 'method'>): Promise<IFetchResponse<RF>> =>
  _fetch({ url, body: body, headers, method: 'PUT' });

const remove = <R = any, D = any, RF = IServiceResponse<R>>({
  url,
  headers,
  body,
}: Omit<IFetchParams<D>, 'method'>): Promise<IFetchResponse<RF>> =>
  _fetch({ url, headers, method: 'DELETE', body: body });

export { _fetch, get, post, put, remove };
