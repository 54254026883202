import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigation } from './Navigation';
import { Header, Footer, ChatBox } from '../components';
import { Box } from '@mui/material';

const PublicRouter: FC = (props) => {
  return (
    <Box className='wrapper'>
      <Header />
      {Navigation.mainNavigation.map((item, index) => (
        <Routes key={index}>
          <Route {...props} path={item.path} element={item.main()} />
        </Routes>
      ))}
      <ChatBox />
      <Footer />
    </Box>
  );
};
export default PublicRouter;
