import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  card: {
    maxWidth: '550px',
    maxHeight: '400px',
    boxShadow: 'none',
    margin: '0 auto',
  },
  content: {
    position: 'relative',
    padding: '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  teamName: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '30px',
    left: '0',
    width: '100%',
    padding: '1rem',
    zIndex: 1,
    color: theme.palette.background.paper,
    '& h3': {
      fontSize: '1.3rem',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '.9rem',
      fontWeight: 400,
      textAlign: 'center',
    },
  },

  imgWrapper: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.3)',
      zIndex: 1,
    },
  },

  img: {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
  },

  teamoverLay: {
    position: 'absolute',
    bottom: '0px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    height: '100%',
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255,255,255, 0.9)',
    '& h3': {
      fontSize: '1.3rem',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '.9rem',
      fontWeight: 400,
      textAlign: 'center',
      marginBottom: '1rem',
    },
  },
  socialIcons: {
    display: 'flex',
    zIndex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    color: theme.palette.lightBlue.dark,
  },
}));
