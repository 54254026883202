import office from '../../assets/office.jpg';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  contactContainer: {
    backgroundImage: `url(${office})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '50vh',
    width: '100%',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '50vh',
      background: 'rgba(0,0,0,0.3)',
    },
  },

  contactWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    position: 'relative',
    zIndex: 1,
    '& h2': {
      color: theme.palette.background.paper,
      fontSize: '4rem',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '3rem',
      },
    },
  },

  section: {
    backgroundColor: theme.palette.grey[100],
  },
  gridLine: {
    padding: '4rem 3rem',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 1.5rem',
    },
  },

  card: {
    padding: '3rem 3rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '3rem 1rem 0',
    },
    '& h4': {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: theme.palette.black[100],
      marginBottom: '2rem',
      textTransform: 'capitalize',
      textAlign: 'center',
    },
  },

  contactCard: {
    '& h4': {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: theme.palette.black[100],
      marginBottom: '1rem',
      textTransform: 'capitalize',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },

  address: {
    marginTop: '1rem',
    '& h4': {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: theme.palette.black[100],
      marginBottom: '.5rem',
      textTransform: 'capitalize',
    },
  },

  contactInfo: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    padding: '2rem',
  },

  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },

  btnSend: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '.5rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    margin: '1rem auto 0',
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  contactCardWrapper: {
    boxShadow: 'none',
    minHeight: 520,
    [theme.breakpoints.down('md')]: {
      minHeight: 0,
    },
  },

  errorMessage: {
    border: `1px solid ${theme.palette.error.main}`,
    outline: 'none',
  },

  error: {
    fontSize: '0.7rem',
    color: theme.palette.error.main,
  },
  textArea: {
    padding: '1rem 0 0 .7rem',
  },
}));
