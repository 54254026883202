import { FC, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routing } from 'src/utils';
import { ActionButton, InputField } from 'src/components/Forms';
import * as service from 'src/service';
import { Formik, Form } from 'formik';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { useStyles } from './BlogSideCardStyles';

const initialState = {
  title: '',
};
const BlogSideCard: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState<Record<string, any>[]>([]);
  const [categories, setCategories] = useState<Record<string, any>[]>([]);
  const [, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([service.blog.getAllBlogs(), service.blog.getAllCategories()])
      .then((res) => {
        setBlogs(res[0].data.blogs);
        setCategories(res[1].data.categories);
        setIsLoading(false);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSearch = (values: { title: string }) => {
    if (!values.title) return;
    navigate(`${routing.blogSearch}?title=${values.title}`);
  };
  return (
    <>
      <Box className={classes.searchWrapper}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent>
            <Typography variant='h2' className={classes.searchTitle}>
              search
            </Typography>

            <Formik
              initialValues={initialState}
              onSubmit={(values) => {
                onSearch(values);
              }}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Box className={classes.searchInput}>
                    <InputField
                      name='title'
                      size='small'
                      value={values.title}
                      className={classes.input}
                      onChange={handleChange}
                    />
                    <ActionButton type='submit' className={classes.btnSearch}>
                      Search
                    </ActionButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>

      <Box className={classes.recentWrapper}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent>
            <Typography variant='h2' className={classes.searchTitle}>
              recent posts
            </Typography>
            {blogs.slice(0, 9).map((blog) => (
              <Link
                to={`${routing.blog}/${blog.slug}`}
                className={classes.blogTitleLink}
                key={blog._id}
              >
                <Box className={classes.recentDescription}>
                  <KeyboardArrowRightIcon />
                  <Typography variant='body1'>{blog.title}</Typography>
                </Box>
              </Link>
            ))}
          </CardContent>
        </Card>
      </Box>

      <Box>
        <Card sx={{ boxShadow: 'none' }}>
          <CardContent>
            <Typography variant='h2' className={classes.searchTitle}>
              categories
            </Typography>

            {categories.map((category) => (
              <Box className={classes.recentDescription} key={category._id}>
                <KeyboardArrowRightIcon />
                <Link
                  to={`${routing.blogCategory}/${category._id}`}
                  className={classes.blogTitleLink}
                >
                  <Typography variant='body1'>{category.name}</Typography>
                </Link>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default BlogSideCard;
