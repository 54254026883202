import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
  },

  gridLine: {
    padding: '3rem 3rem 2rem',
    [theme.breakpoints.down('md')]: {
      padding: '3rem 1.5rem 2rem',
    },
  },

  footerImg: {
    width: '150px',
    marginBottom: '2rem',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      display: 'block',
      marginBottom: '2rem',
    },
  },

  description: {
    fontSize: '1rem',
    fontWeight: 300,
    color: theme.palette.grey[100],
    marginBottom: '1rem',
  },

  socialMedia: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: theme.palette.grey[100],
    '& :hover': {
      color: theme.palette.orange.main,
    },
  },

  discoverWrapper: {
    color: theme.palette.grey[100],
    '& h4': {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginLeft: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
    },
  },

  newDate: {
    color: theme.palette.grey[100],
    fontSize: '1rem',
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    padding: '1rem 0',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },

  link: {
    color: 'inherit',
    textDecoration: 'none',
  },

  list: {
    display: 'inline-block',
  },
}));
