import { FC } from 'react';
import BlogItem from './BlogItem';
import { Loader } from 'src/components';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './BlogCardStyles';

interface IBlogCard {
  blogs: Record<string, any>[];
  isLoading: boolean;
}

const BlogCard: FC<IBlogCard> = ({ blogs, isLoading }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardWrapper}>
      <Grid container spacing={2}>
        {isLoading ? (
          <Loader />
        ) : blogs && blogs.length !== 0 ? (
          blogs.map((blog) => {
            return <BlogItem key={blog._id} blog={blog} />;
          })
        ) : (
          <Typography variant='h2'>No Blogs Found</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default BlogCard;
