import React, { FC, ChangeEventHandler, FocusEventHandler } from 'react';
import { TextareaAutosize } from '@mui/material';

type TextAreaFieldProps = {
  maxRows?: HTMLTextAreaElement['maxLength'];
  minRows?: HTMLTextAreaElement['minLength'];
  className?: HTMLTextAreaElement['className'];
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  disabled?: HTMLTextAreaElement['disabled'];
  name?: HTMLTextAreaElement['name'];
  value?: HTMLTextAreaElement['value'];
  placeholder?: HTMLTextAreaElement['placeholder'];
};

const TextAreaField: FC<TextAreaFieldProps> = ({
  maxRows,
  minRows,
  className,
  name,
  ...props
}) => {
  return (
    <TextareaAutosize
      data-testid='textarea-field'
      maxRows={maxRows}
      minRows={minRows}
      className={className}
      name={name}
      {...props}
    />
  );
};

export default TextAreaField;
