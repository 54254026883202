import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    padding: '1rem 3rem 3rem',
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '2rem 1.5rem',
    },
  },

  textWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    fontSize: '2.5rem',
    fontWeight: 700,
    color: theme.palette.black[100],
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '2rem',
    },
  },

  description: {
    fontSize: '1.2rem',
    color: theme.palette.primary.grey,
    textAlign: 'center',
  },

  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: '4rem',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    color: theme.palette.grey[100],
    minHeight: 350,
    '& p:first-child': {
      fontSize: '1rem',
      fontWeight: 200,
      marginBottom: '1rem',
    },
    '& p:nth-child(2)': {
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: '.3rem',
    },

    '& p:last-child': {
      fontSize: '1rem',
      fontWeight: 300,
      color: theme.palette.grey[300],
    },
  },

  cardContents: {
    backgroundColor: theme.palette.orange.main,
    padding: '4rem',
    color: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      padding: '2rem 2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    minHeight: 350,
    '& p:first-child': {
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
    '& p:nth-child(2)': {
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: '.3rem',
    },

    '& p:last-child': {
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.grey[300],
    },
  },
}));
