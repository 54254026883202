import { FC } from 'react';
import cx from 'classnames';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useStyles } from './TeamsInformationStyles';

interface TeamsInformationProps {
  item: {
    name: string;
    position: string;
    img: string;
    imgWebp: string;
  };
  index: number;
  onMouseOver: (e: any, index: number) => void;
  onMouseOut: (e: any, index: number) => void;
  hover: { [key: number]: boolean };
}

const TeamsInformation: FC<TeamsInformationProps> = ({
  item,
  index,
  onMouseOver,
  onMouseOut,
  hover,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} md={6} key={index}>
      <Card
        className={classes.card}
        onMouseOver={(e) => onMouseOver(e, index)}
        onMouseOut={(e) => onMouseOut(e, index)}
      >
        <CardContent className={classes.content}>
          <Box className={classes.imgWrapper}>
            <img src={item.imgWebp} alt={item.name} className={classes.img} />
          </Box>

          {!hover[index] && (
            <Box className={classes.teamName}>
              <Typography variant='h3'>{item.name}</Typography>
              <Typography variant='body2'>{item.position}</Typography>
            </Box>
          )}

          {hover[index] && (
            <Box className={cx({ [classes.teamoverLay]: hover[index] })}>
              <Typography variant='h3'>{item.name}</Typography>
              <Typography variant='body2'>{item.position}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeamsInformation;
