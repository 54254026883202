import { FC, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Seo } from 'src/constants';
import { Loader } from 'src/components';
import { routing } from 'src/utils';
import * as service from 'src/service';
import { BlogContent } from 'src/components/modules/Blog/BlogDetails';
import { BlogSideCard } from 'src/components/CardBox';
import { Box, Typography, Breadcrumbs, Grid } from '@mui/material';
import { useStyles } from './BlogDetailsStyles';

const BlogDetails: FC = () => {
  const classes = useStyles();
  const [blog, setBlog] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      service.blog
        .getBlogBySlug(slug)
        .then((res) => {
          setBlog(res.data.blog);
          setIsLoading(false);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [slug]);

  if (isLoading) return <Loader />;

  return (
    <section className={classes.section}>
      <Seo title={blog?.title} />
      <Box className={classes.blogWrapper}>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          <Link to={routing.home} className={classes.blogTitleLink}>
            <Typography className={classes.titleNav}>Home</Typography>
          </Link>
          <Link
            to={`${routing.blogCategory}/${blog?.category}`}
            className={classes.blogTitleLink}
          >
            <Typography className={classes.titleNav}>
              {blog?.category}
            </Typography>
          </Link>
          <Typography>
            {blog.slug &&
              blog.slug.charAt(0).toUpperCase() + blog.slug.slice(1)}
          </Typography>
        </Breadcrumbs>

        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          <Grid item xs={12} sm={12} md={8}>
            <BlogContent blog={blog} />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <BlogSideCard />
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default BlogDetails;
