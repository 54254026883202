import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '700px',
    padding: '3rem 3rem 2rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '0rem 1.5rem 3rem',
    },
  },

  title: {
    fontSize: '2rem',
    fontWeight: 700,
    color: theme.palette.black[100],
    marginBottom: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '2rem',
      marginBottom: '0',
      marginTop: '2rem',
    },
  },

  titleMobile: {
    fontSize: '2rem',
    fontWeight: 700,
    color: theme.palette.black[100],
    marginBottom: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '2rem',
      marginBottom: '0',
    },
  },

  description: {
    fontSize: '1.2rem',
    color: theme.palette.primary.grey,
    textAlign: 'center',
    margin: '0 auto 2rem',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      marginTop: '1rem',
    },
  },

  btnProperty: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '1rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    margin: ' 3rem 0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  link: {
    textDecoration: 'none',
  },
}));
