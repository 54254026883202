import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  informationContainer: {
    padding: '3rem 3rem ',
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 1.5rem',
    },
  },
}));
