import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Seo } from 'src/constants';
import * as service from 'src/service';
import { BlogCard } from 'src/components/modules/Blog';
import { Box, Typography } from '@mui/material';
import { useStyles } from '../BlogStyles';

const BlogSearch: FC = () => {
  const location = useLocation();
  const title = new URLSearchParams(location.search).get('title');
  const classes = useStyles();
  const [blogs, setBlogs] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getBlogs = (query: { title: string | null }) => {
    setIsLoading(true);
    service.blog
      .searchBlog(query)
      .then((res) => {
        setBlogs(res.data.blogs);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getBlogs({ title: title });
  }, [title]);

  return (
    <>
      <Seo title={title as string} />
      <Box className={classes.blogContainer}>
        <Box className={classes.blogWrapper}>
          <Typography variant='h2'>Our Blog</Typography>
        </Box>
      </Box>
      <section className={classes.section}>
        <BlogCard blogs={blogs} isLoading={isLoading} />
      </section>
    </>
  );
};

export default BlogSearch;
