import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  sideBar: {
    position: 'sticky',
  },

  logoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  logoWrapperMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.4rem',
  },
  logo: {
    width: '100px',
  },
  person: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    '& p': {
      fontSize: '0.88rem',
      fontWeight: 500,
      color: theme.palette.black[100],
    },
  },

  inputWrapper: {
    marginBottom: '1rem',
  },
  textArea: {
    width: '100%',
    padding: '1rem 0 0 .8rem',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    margin: '1rem 0rem',
    '& p': {
      fontSize: '0.88rem',
      fontWeight: 500,
      color: theme.palette.black[100],
    },
  },
  termUse: {
    fontSize: '0.88rem',
    fontWeight: 500,
    color: theme.palette.lightBlue.main,
  },

  btnGroup: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '.5rem',
  },

  messageWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    justifyContent: 'space-between',
    width: '100%',
  },

  messageWrapperMobile: {
    flexDirection: 'column',
  },

  btnSend: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    padding: '.5rem 1rem',
    width: '100%',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnCall: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    padding: '.43rem 1rem',
    width: '100%',
    border: `1px solid ${theme.palette.green.main}`,
    color: theme.palette.green.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  btnWhatsapp: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    padding: '.5rem 1rem',
    border: `1px solid ${theme.palette.green.main}`,
    color: theme.palette.green.main,
    textTransform: 'capitalize',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.8rem',
  },

  error: {
    border: `1px solid ${theme.palette.error.main}`,
    outline: 'none',
    width: '100%',
  },

  link: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
  },
}));
