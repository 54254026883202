import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
interface ScrollTopProps {
  children: React.ReactElement;
}

const ScrollTop: FC<ScrollTopProps> = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, [pathname]);

  return children;
};

export default ScrollTop;
