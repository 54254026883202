import { FC, useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import * as service from 'src/service';
import { useBottomPopup, ChangeConfirmedPopup } from 'src/components';
import { Formik, Form, FormikValues } from 'formik';
import { SelectField, ActionButton, InputField, InputLabel } from '../../Forms';
import { locationSchema } from 'src/utils/validation';
import { sendLocationInitialValues } from 'src/utils/values';
import { useStyles } from './RightSideFieldsStyles';

const RightSideFields: FC = () => {
  const classes = useStyles();
  const { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [propertyName, setPropertyName] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    service.property
      .getAllPropertyNames()
      .then((res) => {
        setPropertyName(res.data.properties);
      })
      .catch(console.error);
  }, []);

  const onSendLocation = (values: FormikValues) => {
    service.contact
      .sendLocation(values)
      .then((res) => {
        if (res.response.status === 200) {
          changeContinuePopup('Your message has been sent successfully');
          setTimeout(() => {
            changeContinuePopup('Your message has been sent successfully ');
          }, 3000);
        }
      })
      .catch(console.error);
  };

  return (
    <>
      {changingConfirmed && (
        <ChangeConfirmedPopup
          successText={successText}
          changingConfirmed={changingConfirmed}
        />
      )}
      <Card className={classes.cardWrapper}>
        <CardContent>
          <Typography variant='h4'>Get Started Today</Typography>
          <Formik
            initialValues={sendLocationInitialValues}
            validationSchema={locationSchema}
            onSubmit={(values, actions) => {
              onSendLocation(values);
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box className={classes.fieldsWrapper}>
                  <InputField
                    placeholder='name...'
                    type='text'
                    value={values.name}
                    onChange={handleChange}
                    name='name'
                    InputProps={{
                      className: classes.input,
                    }}
                    size='small'
                  />

                  <InputField
                    placeholder='email...'
                    type='text'
                    value={values.email}
                    onChange={handleChange}
                    name='email'
                    InputProps={{
                      className: classes.input,
                    }}
                    size='small'
                  />

                  <InputField
                    placeholder='phone number...'
                    type='text'
                    value={values.phone}
                    onChange={handleChange}
                    name='phone'
                    InputProps={{
                      className: classes.input,
                    }}
                    size='small'
                  />

                  <Box>
                    <InputLabel
                      title='SELECT A LOCATION OF INTEREST*'
                      className={classes.label}
                    />
                    <SelectField
                      value={values.location}
                      options={propertyName.map((name) => ({
                        _id: name._id,
                        value: name._id,
                      }))}
                      className={
                        errors.location && touched.location
                          ? classes.inputError
                          : classes.select
                      }
                      name='location'
                      onChange={(e) => {
                        setFieldValue('location', e.target.value);
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Please select an option...'
                      fullWidth
                    />
                    {errors.location && touched.location && (
                      <Box className={classes.error}>{errors.location}</Box>
                    )}
                  </Box>

                  <Box>
                    <InputLabel
                      title='HOW SOON DO YOU INTEND TO PURCHASE?*'
                      className={classes.label}
                    />
                    <SelectField
                      value={values.howSoon}
                      name='howSoon'
                      options={[
                        { _id: 'Immediately', value: 'Immediately' },
                        { _id: 'Within 3 Months', value: 'Within 3 Months' },
                        { _id: 'Within  a year', value: 'Within a Year' },
                        {
                          _id: 'Just making enquiry',
                          value: 'Just making enquiry',
                        },
                      ]}
                      className={
                        errors.howSoon && touched.howSoon
                          ? classes.inputError
                          : classes.select
                      }
                      onChange={(e) => {
                        setFieldValue('howSoon', e.target.value);
                      }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      placeholder='Please select an option...'
                      fullWidth
                    />
                    {errors.howSoon && touched.howSoon && (
                      <Box className={classes.error}>{errors.howSoon}</Box>
                    )}
                  </Box>

                  <ActionButton
                    disabled={isSubmitting}
                    className={classes.btnGetDetails}
                    type='submit'
                  >
                    get more details
                  </ActionButton>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};

export default RightSideFields;
