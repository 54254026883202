import { FC } from 'react';
import BlogFeaturesItems from './BlogFeaturesItems';
import { Loader } from 'src/components';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './BlogCardFeaturesStyles';

interface IBlogCardFeatures {
  blogs: Record<string, any>[];
  isLoading: boolean;
}
const BlogCardFeatures: FC<IBlogCardFeatures> = ({ blogs, isLoading }) => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={2}>
        {isLoading ? (
          <Loader />
        ) : blogs && blogs.length !== 0 ? (
          blogs.map((blog) => {
            return <BlogFeaturesItems key={blog._id} blog={blog} />;
          })
        ) : (
          <Typography className={classes.notFound} variant='h2'>
            No Blogs Found
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default BlogCardFeatures;
