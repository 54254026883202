import { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import CarouselImage from '../CarouselImage';
import ImgsViewer from 'react-images-viewer';

interface IPropertiesImage {
  property: Record<string, any>;
  imageViewer: {
    show: boolean;
    currentImg: number;
  };
  prevImage: () => void;
  nextImage: () => void;
  closeImageViewer: () => void;
  setImageViewer: (imageViewer: any) => void;
}

const PropertiesImage: FC<IPropertiesImage> = ({
  property,
  imageViewer,
  prevImage,
  nextImage,
  closeImageViewer,
  setImageViewer,
}) => {
  return (
    <>
      {property?.propertyImage?.length > 1 ? (
        <Carousel>
          {property?.propertyImage?.map((image: any, i: number) => {
            return (
              <CarouselImage
                key={i}
                image={image}
                i={i}
                property={property}
                setImageViewer={setImageViewer}
              />
            );
          })}
        </Carousel>
      ) : (
        <>
          {property?.propertyImage?.map((image: any, i: number) => {
            return (
              <CarouselImage
                key={i}
                image={image}
                i={i}
                property={property}
                setImageViewer={setImageViewer}
              />
            );
          })}
        </>
      )}

      {property?.propertyImage?.length > 1 && (
        <ImgsViewer
          imgs={property?.propertyImage.map((image: any) => {
            return {
              src: image.url,
            };
          })}
          backdropCloseable={true}
          currImg={imageViewer.currentImg}
          isOpen={imageViewer.show}
          onClickPrev={prevImage}
          onClickNext={nextImage}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default PropertiesImage;
