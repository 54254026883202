import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from 'src/utils';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './Page404Styles';

const Page404: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.notFoundContainer}>
      <Box className={classes.notFoundWrapper}>
        <Typography variant='h1'>404</Typography>
        <Typography variant='body2'>PAGE NOT FOUND</Typography>
        <Box className={classes.btnWrapper}>
          <Button
            className={classes.btnBack}
            onClick={() => navigate(routing.home)}
          >
            Go Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Page404;
