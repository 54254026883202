import { FC, useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Loader } from 'src/components';
import { Seo } from 'src/constants';
import * as service from 'src/service';
import { routing } from 'src/utils';
import { ActionButton } from 'src/components/Forms';
import {
  PropertiesSideFeatures,
  PropertiesDetailsCards,
} from 'src/components/modules/Properties';
import RoomIcon from '@mui/icons-material/Room';
import { Box, Typography, Breadcrumbs, Grid } from '@mui/material';
import { useStyles } from './PropertiesDetailsStyles';

const PropertiesDetails: FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [property, setProperty] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      service.property
        .getPropertyBySlug(slug)
        .then((res) => {
          setProperty(res.data.property);
          setIsLoading(false);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [slug]);

  if (isLoading) return <Loader />;

  return (
    <section className={classes.section}>
      <Seo title={property?.propertyName} />
      <Box className={classes.container}>
        <Box sx={{ marginBottom: '1rem' }}>
          <Breadcrumbs separator='›' aria-label='breadcrumb'>
            <Link to={routing.home} className={classes.link}>
              <Typography className={classes.breadcrumbs}>Home</Typography>
            </Link>
            <Link
              to={`${routing.propertyType}/${property?.propertyType}`}
              className={classes.link}
            >
              <Typography className={classes.breadcrumbs}>
                {property?.propertyType}
              </Typography>
            </Link>
            <Typography color='text.primary'>
              {slug && slug.split('-').join(' ')}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box className={classes.titleWrapper}>
          <Typography variant='h2'>
            {property?.propertyName?.length > 100
              ? property?.propertyName?.substring(0, 100) + '...'
              : property?.propertyName}
          </Typography>
          <Typography variant='h2'>
            ₦{property?.propertyPrice?.toLocaleString()}
          </Typography>
        </Box>
        <Box className={classes.btnGroup}>
          {property.propertyFeatured && (
            <ActionButton className={classes.btnFeatured}>
              {property?.propertyFeatured}
            </ActionButton>
          )}

          <ActionButton
            onClick={() =>
              navigate(`${routing.propertyStatus}/${property?.propertyStatus}`)
            }
            className={classes.btnStatus}
          >
            {property?.propertyStatus}
          </ActionButton>
        </Box>
        <Box className={classes.locationWrapper}>
          <RoomIcon />
          <Typography variant='body1'>
            {property?.propertyAddress?.city},&nbsp;
            {property?.propertyAddress?.street},&nbsp;
            {property?.propertyAddress?.country}
          </Typography>
        </Box>

        <Box sx={{ marginTop: '1rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <PropertiesDetailsCards property={property} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <PropertiesSideFeatures propertyName={property?.propertyName} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </section>
  );
};

export default PropertiesDetails;
