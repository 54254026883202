import { FC } from 'react';

interface ImageProps {
  src: string;
  fallback: string;
  type?: string;
  className?: string;
  alt?: string;
}

const Image: FC<ImageProps> = ({
  src,
  fallback,
  type = 'image/webp',
  alt,
  ...delegated
}) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} alt={alt} {...delegated} />
    </picture>
  );
};

export default Image;
