import { FC } from 'react';

interface ICarouselImage {
  property: Record<string, any>;
  setImageViewer: (imageViewer: any) => void;
  i: number;
  image: {
    url: string;
  };
}

const CarouselImage: FC<ICarouselImage> = ({
  property,
  setImageViewer,
  i,
  image,
}) => {
  return (
    <img
      key={i}
      src={image.url}
      onClick={() => {
        if (property.propertyImage.length) {
          setImageViewer({
            show: true,
            currentImg: 0,
          });
        }
      }}
      style={{
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        cursor: property.propertyImage.length > 1 ? 'pointer' : 'default',
      }}
      alt={`${i} Slide`}
    />
  );
};

export default CarouselImage;
