import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    top: '0',
    right: '0',
    left: '0',
    position: 'fixed',
    padding: '.5rem 1.5rem',
    zIndex: 5,
    backgroundColor: theme.palette.primary.main,
  },

  IconWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  logo: {
    width: '70px',
    backgroundColor: theme.palette.background.default,
    alignSelf: 'center',
    margin: '0 auto',
    cursor: 'pointer',
  },

  navMenuSide: {
    padding: '40px 40px',
    position: 'fixed',
    top: '48px',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[100],
    overflowY: 'auto',
    left: '-100%',
    width: '50%',
    transition: '.8s',
    textAlign: 'left',
  },

  navMenuOpen: {
    left: '0',
    transition: '.4s',
  },
  navWrapper: {},
  listItem: {
    cursor: 'pointer',
  },
}));
