import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  propertiesNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h2': {
      fontSize: '1.3rem',
      fontWeight: 600,
      textTransform: 'capitalize',
      color: theme.palette.grey[700],
    },
  },
  select: {
    minWidth: 150,
    maxHeight: 40,
  },

  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },

  card: {
    boxShadow: 'none',
  },

  cardRow: {
    boxShadow: 'none',
  },
  cardContent: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  propertyImg: {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
  },

  screenWrapper: {
    height: '100vh',
    width: '100%',
  },

  propertyContent: {
    padding: '.7rem 1rem',
  },

  btnStatusWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.4rem',
    marginBottom: '.5rem',
  },

  imgWrapper: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '97%',
      background: 'rgba(0,0,0,0.3)',
      zIndex: 1,
    },
  },

  btnFeatured: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '0rem .4rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
    },
  },
  btnStatus: {
    backgroundColor: theme.palette.grey[600],
    textTransform: 'capitalize',
    padding: '0rem .4rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  price: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '.6rem',
    '& h4': {
      fontSize: '1.4rem',
      fontWeight: 600,
      color: theme.palette.black[100],
    },
  },

  propertyInfo: {
    marginBottom: '1rem',
    '& h4': {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: theme.palette.black[100],
      marginBottom: '.6rem',
      '&:hover': {
        color: theme.palette.lightBlue.dark,
      },
    },
    '& > p': {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: theme.palette.grey[700],
      marginBottom: '1rem',
    },
  },

  rowDescription: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: theme.palette.grey[700],
    marginBottom: '1rem',
    minHeight: '3rem',
  },

  sizeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  propertyAdditionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'unset',
    },
  },
  propertyTimeWraper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      gap: '1rem',
    },
  },
  byUser: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    '& > p': {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: theme.palette.grey[700],
    },
  },
  timePosted: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    '& > p': {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: theme.palette.grey[700],
    },
  },
  btnDetails: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  propertyWrapperMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 'unset',
  },

  propertiesNumberMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '1rem',
  },

  btnStatusRowWrapper: {
    position: 'absolute',
    top: '.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0rem 1rem',
    zIndex: 2,
  },

  groupFeatured: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  priceWrapper: {
    position: 'absolute',
    bottom: '.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0rem 1rem',
    marginBottom: '.4rem',
    zIndex: 2,
    color: theme.palette.grey[100],
    '& h4': {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },

  sizeWrapperRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  propertyAdditionalInfoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'unset',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },

  notFound: {
    fontSize: '2rem',
    fontWeight: 600,
    color: theme.palette.black[100],
  },
}));
