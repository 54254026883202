import { FC } from 'react';
import LeftSideFields from './LeftSideFields';
import RightSideFields from './RightSideFields';
import { Box, Grid } from '@mui/material';
import { useStyles } from './SearchPropertiesStyles';

const SearchProperties: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.informationContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <LeftSideFields />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <RightSideFields />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchProperties;
