import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image } from 'src/components';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { routing } from 'src/utils';
import { ActionButton } from 'src/components/Forms';
import moment from 'moment';
import cx from 'classnames';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useStyles } from './PropertiesCardStyles';

interface IGridRow {
  property: Record<string, any>;
}

const GridRow: FC<IGridRow> = ({ property }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handle = useFullScreenHandle();
  const isNonMobile = useMediaQuery('(max-width: 410px)');
  const isNonScreen = useMediaQuery('(max-width: 900px)');

  return (
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <Card className={classes.cardRow}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.imgWrapper}>
            <Link
              to={`${routing.property}/${property.slug}`}
              className={classes.link}
            >
              <FullScreen handle={handle}>
                <Image
                  src={property.propertyImage[0].url}
                  alt={property.propertyName}
                  fallback={property.propertyImage[0].url}
                  className={
                    handle.active ? classes.screenWrapper : classes.propertyImg
                  }
                />
              </FullScreen>
              <Box className={classes.btnStatusRowWrapper}>
                {property.propertyFeatured && (
                  <ActionButton className={classes.btnFeatured}>
                    {property.propertyFeatured}
                  </ActionButton>
                )}
                <Box className={classes.groupFeatured}>
                  <ActionButton className={classes.btnStatus}>
                    {property.propertyStatus}
                  </ActionButton>
                </Box>
              </Box>
            </Link>

            <Box className={classes.priceWrapper}>
              <Typography variant='h4'>
                ₦{property.propertyPrice.toLocaleString()}
              </Typography>
              {!isNonScreen && <CloseFullscreenIcon onClick={handle.enter} />}
            </Box>
          </Box>
          <Box className={classes.propertyContent}>
            <Box className={classes.propertyInfo}>
              <Link
                to={`${routing.property}/${property.slug}`}
                className={classes.link}
              >
                <Typography variant='h4'>
                  {property.propertyName.length > 70
                    ? property.propertyName.substring(0, 70) + '...'
                    : property.propertyName}
                </Typography>
              </Link>
              <Typography className={classes.rowDescription} variant='body2'>
                {property.propertyAddress.city},
                {property.propertyAddress.street},
                {property.propertyAddress.country}
              </Typography>
              <Box className={classes.sizeWrapperRow}>
                <Typography variant='body2'>
                  {property.propertySize} sqm
                </Typography>
                <Typography variant='body2'>{property.propertyType}</Typography>
              </Box>
            </Box>
            <Box
              className={cx(classes.propertyAdditionalInfoRow, {
                [classes.propertyWrapperMobile]: isNonMobile,
              })}
            >
              <Box className={classes.timePosted}>
                <CalendarMonthIcon />
                <Typography variant='body2'>
                  {moment.utc(property.createdAt).startOf('minute').fromNow()}
                </Typography>
              </Box>
              <ActionButton
                onClick={() => navigate(`${routing.property}/${property.slug}`)}
                className={classes.btnDetails}
              >
                Details
              </ActionButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GridRow;
