import React, { FC } from 'react';
import Slider from 'react-slick';

import { ChangeSettings } from '../../../constants/ChangingSlider';

interface CitySliderProps {
  children: React.ReactNode;
}
const CitySlider: FC<CitySliderProps> = ({ children }) => {
  return <Slider {...ChangeSettings}>{children}</Slider>;
};

export default CitySlider;
