import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  notFoundContainer: {
    display: 'grid',
    width: '100%',
    height: 'calc(var(--app-height) - (89px + 184px))',
    top: 0,
    minHeight: '500px',
    right: 0,
    left: 0,
    bottom: 0,
    background: theme.palette.grey[100],
  },

  notFoundWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& h1': {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 200,
      fontSize: '190.931px',
      lineHeight: '137px',
      color: theme.palette.primary.main,
    },
    '& p': {
      textAlign: 'center',
      fontWeight: '300',
      fontSize: '40.072px',
      lineHeight: '60px',
      display: 'block',
      marginTop: '16px',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
  },

  btnWrapper: {
    display: 'grid',
    justifyContent: 'center',
  },

  btnBack: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '.7rem 2rem',
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    color: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },
}));
