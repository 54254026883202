import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image } from 'src/components';
import moment from 'moment';
import { routing } from 'src/utils';
import { ActionButton } from 'src/components/Forms';
import cx from 'classnames';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useStyles } from './PropertiesCardStyles';

interface IGridColumn {
  property: Record<string, any>;
}
const GridColumn: FC<IGridColumn> = ({ property }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const isNonMobile = useMediaQuery('(max-width: 410px)');

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Link
                to={`${routing.property}/${property.slug}`}
                className={classes.link}
              >
                <Box className={classes.imgWrapper}>
                  <Image
                    src={property.propertyImage[0].url}
                    alt={property.propertyName}
                    fallback={property.propertyImage[0].url}
                    className={classes.propertyImg}
                  />

                  <Box className={classes.btnStatusRowWrapper}>
                    {property.propertyFeatured && (
                      <ActionButton className={classes.btnFeatured}>
                        {property.propertyFeatured}
                      </ActionButton>
                    )}
                  </Box>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box className={classes.propertyContent}>
                <Box className={classes.btnStatusWrapper}>
                  <ActionButton
                    onClick={() =>
                      navigate(
                        `${routing.propertyStatus}/${property?.propertyStatus}`
                      )
                    }
                    className={classes.btnStatus}
                  >
                    {property.propertyStatus}
                  </ActionButton>
                </Box>
                <Box className={classes.price}>
                  <Typography variant='h4'>
                    ₦{property.propertyPrice.toLocaleString()}
                  </Typography>
                </Box>
                <Box className={classes.propertyInfo}>
                  <Link
                    to={`${routing.property}/${property.slug}`}
                    className={classes.link}
                  >
                    <Typography variant='h4'>
                      {property.propertyName.length > 50
                        ? property.propertyName.substring(0, 70) + '...'
                        : property.propertyName}
                    </Typography>
                  </Link>
                  <Typography variant='body2'>
                    {property.propertyAddress.city},
                    {property.propertyAddress.street},
                    {property.propertyAddress.country}
                  </Typography>
                  <Box className={classes.sizeWrapper}>
                    <Typography variant='body2'>
                      {property.propertySize} sqm
                    </Typography>
                    <Typography variant='body2'>
                      {property.propertyType}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.propertyAdditionalInfo}>
                  <Box
                    className={cx(classes.propertyTimeWraper, {
                      [classes.propertyWrapperMobile]: isNonMobile,
                    })}
                  >
                    <Box className={classes.timePosted}>
                      <CalendarMonthIcon />
                      <Typography variant='body2'>
                        {moment
                          .utc(property.createdAt)
                          .startOf('minute')
                          .fromNow()}
                      </Typography>
                    </Box>
                  </Box>

                  <ActionButton
                    onClick={() =>
                      navigate(`${routing.property}/${property.slug}`)
                    }
                    className={classes.btnDetails}
                  >
                    Details
                  </ActionButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GridColumn;
