import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  schedule: {
    '& h2': {
      fontSize: '1.7rem',
      fontWeight: 700,
      color: theme.palette.black[100],
      marginBottom: '2rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  selectField: {
    color: theme.palette.grey[500],
  },
  textArea: {
    width: '100%',
    padding: '1rem 0 0 .7rem ',
  },

  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },

  btnSchedule: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '0.88rem',
    fontWeight: '500',
    padding: '.5rem 1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8',
      boxShadow: 'none',
    },
  },

  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    margin: '1rem 0rem',
    '& p': {
      fontSize: '0.88rem',
      fontWeight: 500,
      color: theme.palette.black[100],
    },
  },
  termUse: {
    fontSize: '0.88rem',
    fontWeight: 500,
    color: theme.palette.lightBlue.main,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.8rem',
  },

  error: {
    border: `1px solid ${theme.palette.error.main}`,
    outline: 'none',
  },
}));
